.AdminLayout {
    min-height: 100vh;
    display: flex;

    h1 {
        margin: 0px 0px 20px;
        font-size: 28px;

        .arrow {
            color: #233856;
            width: 30px;
            height: 30px;
            position: relative;
            top: 4px;
        }

        a {
            img {
                width: 26px;
                margin-right: 10px;
                border-radius: 4px;
            }
        }
    }

    .blocks {
        display: grid;
        grid-template-columns: 300px auto;
        flex: 1;

        .leftBlock {
            background-color: #125BCA09;
            display: flex;
            flex-direction: column;

            h1 {
                margin: 10px 10px 60px;
                font-size: 30px;
                text-align: center;
            }

            .buttons {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 10px;

                button {
                    .icon {
                        margin-left: 10px;
                    }
                }
            }
        }

        .children {
            margin: 10px 10px 10px 20px;
        }
    }
}