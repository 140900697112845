@import "../../mixins.scss";

.Error404Page {
    .content {
        margin: 144px auto;
        width: 1216px;

        @include for-768 {
            width: 816px;
        }

        @include for-395 {
            width: 328px;
            margin: 112px auto;
        }

        h1 {
            font-size: 36px;
            line-height: 40px;
            font-weight: 600;

            @include for-395 {
                font-size: 30px;
                line-height: 36px;
            }
        }

        .blocks {
            display: grid;
            grid-template-columns: 510px 598px;
            gap: 64px;
            width: 1172px;

            @include for-768 {
                width: auto;
                display: flex;
                flex-direction: column;
                gap: 64px;
                align-items: center;
            }

            @include for-395 {
                gap: 40px;
            }

            .block {
                margin-top: 120px;
                text-align: center;

                @include for-768 {
                    width: 510px;
                    height: 256px;
                    margin-top: 0px;
                }

                @include for-395 {
                    width: 100%;
                    height: auto;
                }

                .description {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    width: 420px;
                    margin: 40px auto 28px;

                    @include for-395 {
                        width: 100%;
                    }
                }

                .btn {
                    font-size: 16px;
                    line-height: 28px;
                    font-weight: 600;
                    height: 60px;
                    width: 272px;

                    @include for-395 {
                        width: 100%;
                    }
                }
            }

            .img {
                height: 496px;

                @include for-395 {
                    width: 100%;
                    height: auto;
                }

                img {
                    height: 100%;

                    @include for-395 {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}