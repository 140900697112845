@import "../../mixins.scss";
@import "../../colors.scss";

.SeaFreightPage {
  overflow-x: hidden;

  * {
    font-family: "Montserrat", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  .breadcrumbs {
    margin-top: 64px;
  }

  h1.title {
    margin-top: 112px;
    max-width: 598px;

    @include for-395 {
      margin-top: 80px;
    }
  }

  .cardList1 {
    margin: 60px 0 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    list-style-type: none;

    @include for-768 {
      grid-template-columns: 1fr;
    }
  }

  .blockContent {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    row-gap: 200px;

    @include for-768 {
      row-gap: 160px;
    }

    @include for-395 {
      margin-top: 40px;
    }
  }

  .pageContent {
    margin: 64px auto 200px;
    width: 1216px;
    text-align: left;

    @include for-768 {
      width: 684px;
      margin-bottom: 160px;
    }

    @include for-395 {
      width: 328px;
    }

    .title {
      font-size: 48px;
      line-height: 1;
      color: $neutral900;

      @include for-395 {
        font-size: 36px;
        line-height: 40px;
        width: auto;
      }
    }

    .gridType1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;

      & > *:first-child {
        max-width: 495px;
      }

      @include for-768 {
        grid-template-columns: 1fr;
        column-gap: 0;
      }

      @include for-395 {
        width: auto;
      }
    }

    .sectionAbout {
      position: relative;
      grid-template-columns: 495px 1fr;
      column-gap: 71px;

      &.gridType1 {
        @include for-768 {
          grid-template-columns: 1fr;
          row-gap: 60px;

          & > *:first-child {
            max-width: 480px;
          }
        }
      }

      .account {
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0px 4px 6px -2px #94A3B80D, 0px 10px 15px -3px #94A3B81A;

        @include for-395 {
          border-radius: 8px;
        }
      }

      .container {
        position: sticky;
        top: 100px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include for-768 {
          position: static;
          top: 0;
        }

        @include for-395 {
          gap: 40px;
        }
      }

      .text {
        font-size: 24px;
        line-height: 32px;
        color: #0F172A;
        font-weight: 600;

        @include for-395 {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }

    .sectionAdvantages {
      .title {
        max-width: 598px;
      }
    }

    .sectionSubscription {
      .title {
        text-align: center;

        @include for-768 {
          text-align: left;
        }
      }

      .container {
        display: flex;
        flex-direction: column;
      }
    }

    .sectionWho {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 60px;

      @include for-768 {
        flex-direction: column;
      }

      @include for-395 {
        gap: 40px;
      }

      .title {
        position: sticky;
        top: 100px;
        max-width: 508px;
        height: fit-content;
        z-index: 2;

        @include for-768 {
          position: static;
        }
      }

      .commonList {
        margin: 136px 62px 0 0;
        padding: 0;
        width: 536px;

        @include for-768 {
          margin: 0;
        }
      }
    }

    .sectionUsefull {

      .container {
        margin: 60px 0 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 346px);
        gap: 20px;
        list-style-type: none;

        @include for-768 {
          grid-template-columns: 1fr;
          grid-template-rows: none;
          grid-auto-rows: 346px;
        }

        @include for-395 {
          margin-top: 40px;
          grid-template-rows: repeat(3, 314px) 260px;
          gap: 12px;
        }
      }

      .listItemImg {
        display: flex;
        justify-content: center;
        align-items: center;

        .img {
          width: auto;
          max-height: 266px;

          @include for-395{
            max-height: 162px;
          }
        }
      }
    }
  }

  .buttonGroup {
    margin: 60px auto 40px auto;

    @include for-768 {
      margin: 60px 0 40px 0;
    }

    button {
      border-width: 1.5px;
      border-style: solid;
      border-color: #E2E8F0;
      font-size: 14px;
      line-height: 20px;
      color: #94A3B8;
      font-weight: 400;
      padding: 10.5px 20px !important;
      margin: 0px !important;
      box-sizing: border-box;

      &:hover {
        border-color: #E2E8F0;
      }

      &.active {
        border: 1.5px solid $brand600 !important;
        background-color: #EFF6FF;
        font-size: 14px;
        line-height: 20px;
        color: $neutral700;
        font-weight: 600;
      }
    }
  }

  .tariffCardWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include for-768 {
      flex-direction: column;
    }
  }

  .demoBlock {
    padding: 40px;
    position: relative;
    background-color: $neutral50;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    text-align: center;
    margin-top: 20px;

    @include for-395 {
      padding: 24px 14px;
      gap: 12px;
    }

    h3 {
      @include for-395 {
        max-width: 230px;
      }
    }
  }
}
