.LangSwitcher2 {
    .select {
        fieldset {
            border: 0px;
        }

        .MuiSelect-select {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: #334155;
            padding: 12px 20px 12px 20px !important;
            padding-right: 26px;
            display: flex;
            align-items: center;
        }

        .selectIcon {
            color: #2563EB;
            position: absolute;
            right: 0px;
            pointer-events: none;
            transform: rotate(180deg);

            &.open {
                transform: rotate(0deg);
            }
        }
    }

    .flagIcon {
        margin-right: 8px;
    }
}

.LangSwitcher2-Items {
    .MuiPaper-root {
        box-shadow: 0px 4px 6px -2px #94A3B80D, 0px 10px 15px -3px #94A3B81A;
        border-radius: 4px;    }

    ul {
        margin: 0px;
        padding: 0px;

        li {
            padding: 12px 20px;
            font-size: 14px;
            line-height: 20px;
            color: #334155;
            font-weight: 400;
            background-color: #FFF !important;

            &.Mui-selected {
                font-weight: 600;
            }

            &:hover {
                background-color: #F1F5F9 !important;
            }

            .flagIcon {
                margin-right: 8px;
            }
        }
    }
}
