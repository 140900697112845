@import "./mixins.scss";
@import "./colors.scss";

@font-face {
    font-family: 'Arkhip';
    src: url('./assets//fonts/Arkhip.ttf');
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets//fonts/Montserrat-weight.ttf');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('./assets//fonts/Montserrat-Medium.ttf');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('./assets//fonts/Montserrat-Bold.ttf');
}

@font-face {
    font-family: 'MontserratExtraBold';
    src: url('./assets//fonts/Montserrat-ExtraBold.ttf');
}

body {
    margin: 0px !important;
    padding: 0px !important;
    font-family: "Montserrat";
    overflow-x: hidden;
    overflow-y: inherit !important;
}

b {
    font-family: "Montserrat";
    font-weight: 600;
}

button {
    &.MuiButton-root {
        font-family: "Montserrat";
        text-transform: none;
    }
}

h1 {
    margin: 0px;
    display: block;
    color: #0F172A;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
}

h2 {
    color: #0F172A;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    margin: 0px;
}

h3 {
    color: #0F172A;
    padding: 0px;
    margin: 0px;
    font-size: 24px;
    line-height: 32px;
    font-family: "Montserrat";
    font-weight: 600;
}

.text-base {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
}

p {
    font-family: "Montserrat" !important;
}

br {
    clear: both;
}

.hide {
    display: none !important;
}

a {
    font-family: "Montserrat";
    color: #2563EB;
}

.breadcrumbs {

    a {
        color: #64748B;
        text-decoration: none;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;

        &:hover {
            color: #2563EB;
        }
    }

    .noLink {
        color: #64748B;
        text-decoration: none;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .current {
        color: #2563EB;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }

    .MuiBreadcrumbs-separator {
        color: #829FC8;
    }

    .bc_divider {
        width: 6px;
    }
}

.MuiCheckbox-root {
    padding: 0px;
    margin: 0px;
}


.MuiTooltip-popper > div {
    background-color: #f8f8f8;
    box-shadow: 0px 0px 8px #888;

    .tooltip {
        font-size: 14px;
        padding: 4px;
        color: #333
    }
}

.rnc__notification-item--warning {
    background-color: #a97f00;
    border-left: 8px solid #ffbe00;
}

.snackErrorText {
    position: relative;
    top: -6px;
}

.snackSuccessText {
    position: relative;
    top: -6px;
}

.snackWarningText {
    position: relative;
    top: -6px;
}

.MuiButtonBase-root {
    &.btn {
        box-sizing: border-box;
        background-color: #2563EB;
        color: #FFF;
        text-transform: none;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        padding: 12px 24px;
        box-shadow: none;
        gap: 8px;

        &:hover {
            background-color: #3B82F6;
        }
    }

    &.btn-np {
        background-color: #2563EB;
        color: #FFF;
        text-transform: none;
        font-size: 14px;
        font-family: "Montserrat";
        font-weight: 600;
        letter-spacing: 0.0125em;

        &:hover {
            background-color: #3B82F6;
        }
    }

    &.outlined {
        background-color: transparent;
        color: #334155;

        &:hover {
            background-color: #2563EB11;
        }
    }
}

.MuiAutocomplete-root {
    input {
        padding: 12px 10px !important;
    }
}

.MuiAutocomplete-popper {
    .MuiPaper-root {
        box-shadow: 0px 4px 6px -2px #94A3B80D, 0px 10px 15px -3px #94A3B81A;
    }

    ul.MuiAutocomplete-listbox {
        margin: 0px !important;
        padding: 0px !important;

        .MuiAutocomplete-option {
            padding: 12px 20px !important;
            margin: 0px !important;

            &:hover {
                background-color: #F1F5F9;
            }

            &.Mui-focused {
                background-color: transparent;
            }

            .label {
                margin: 0px;
                padding: 0px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #334155;
            }

            .description {
                margin: 4px 0px 0px 0px;
                padding: 0px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: #94A3B8;
            }
        }
    }
}

.dropDownOption {
    display: block !important;
    margin: 8px 0px;
    cursor: pointer;

    &:hover {
        background-color: #F1F5F9 !important;
    }

    .label {
        margin: 6px 20px 0px;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
        font-family: "Montserrat";
    }

    .description {
        margin: 0px 20px 6px;
        font-size: 12px;
        line-height: 16px;
        color: #94A3B8;
        font-family: "Montserrat";
    }
}

.dialog {

    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        color: #304564;
        opacity: 1;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }
    }

    .label {
        color: #334155;
        font-family: "MontserratMedium";
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 6px;
    }

    .MuiDialog-paper {
        max-width: 730px;
        width: 730px;
        //background-color: #E9F3FD;

        .req {
            color: #F87171;
            display: inline;
            margin-left: 6px;
        }

        .MuiDialogTitle-root {
            color: #0F172A;
            font-size: 36px;
            line-height: 40px;
            text-transform: none;
            margin: 24px;
            padding: 0px;
            margin-bottom: 0px;
            font-weight: 600;
            font-family: "Montserrat";
        }

        .MuiDialogContent-root {
            margin: 24px;
            padding: 0px;

            .description {
                color: #28282888;
            }

            .form {
                margin-top: 0px;
                background-color: #FFF;
                padding: 0px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                border-radius: 4px;

                .full {
                    width: 100%;
                }
            }

            button {
                width: max-content;
            }
        }
    }

    .btn {Mui-selectedMui-selected {
            background-color: #3521FF;
            border-radius: 8px;
        }
    }

    .MuiCalendarPicker-viewTransitionContainer {
        .MuiTypography-root {
            font-family: "Montserrat";
            text-transform: capitalize;
        }
    }
}

input, textarea {
    font-family: "Montserrat" !important;
}

.MuiFormHelperText-sizeMedium {
    margin-top: -2px !important;
    height: 14px;
}

.mr {
    margin-right: 10px !important;
}

.grid-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
}

.grid-1-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
}

.btnWithIcon {
    padding: 2px 6px !important;
    min-width: auto !important;

    .btnIcon {
        width: 20px !important;
    }
}

button {
    &.actionBtn {
        padding: 4px;
        min-width: auto;

        .icon {
            width: 20px;
            height: 20px;
        }
    }
}

.link, a {
    color: #2563EB;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

ul.MuiList-root {
    li {
        font-family: "Montserrat";
    }
}

.textarea {
    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #C9CFDB;
    }
}

.MuiModal-root.MuiPopover-root {
    z-index: 2500 !important;
}

.MuiDialog-root {
    z-index: 2500 !important;
}

.MuiPickersPopper-root {
    z-index: 2300 !important;
}

.MainLayout, .MuiDialog-root {
    .MuiCheckbox-root {
        width: 20px;
        height: 20px;

        > svg {
            > path {
                color: #C9CFDB;
            }
        }

        &.Mui-checked  {
            > svg {
                > path {
                    color: #2563EB !important;
                }
            }
        }
    }

    .MuiOutlinedInput-root {
        margin: 0px;
        padding: 0px;
        border-color: #E2E8F0;
        background: $white;

        input {
            color: #334155;
            font-weight: 400;
            padding: 12px 20px;
            font-size: 16px;
            line-height: 24px;
            height: 24px;
            background: $white;
            border-radius: 4px;

            &::placeholder {
                color: #94A3B8;
                font-weight: 400;
            }
        }

        textarea {
            color: #334155;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            padding: 12px 20px;
            height: 24px;

            &::placeholder {
                color: #94A3B8;
                font-weight: 400;
            }
        }

        &.Mui-disabled {
            input {
                background: linear-gradient(0deg, #F8FAFC, #F8FAFC);
                color: #94A3B8;
                -webkit-text-fill-color: #94A3B8;
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: #E2E8F0 !important;
                border-width: 2px;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #E2E8F0;
            border-width: 2px;
        }

        &.Mui-error {
            .MuiOutlinedInput-notchedOutline {
                border-color: #F84437 !important;
            }
        }
    }

    .Mui-error {
        .MuiOutlinedInput-notchedOutline {
            border-color: #F84437 !important;
        }

        .MuiCheckbox-root {
            > svg {
                > path {
                    color: #F84437;
                }
            }
        }
    }
}

.MuiInputBase-root:hover {
    .MuiOutlinedInput-notchedOutline {
        border-color: #2563EB !important;
    }
}

.success {
    .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
            border-color: #0CA170 !important;
        }
    }
}

.btn {
    &.Mui-disabled {
        background-color: #C9CFDB;
        color: #FFF;
    }

    &:hover {
        background-color: #3B82F6;
        box-shadow: none !important;
    }
}

.MuiSelect-select {
    font-family: "Montserrat" !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #C9CFDB;
  -webkit-text-fill-color: #304564;
  -webkit-box-shadow: 0;
  transition: background-color 5000s ease-in-out 0s;
}


.linkBtn {
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    color: #2563EB;
    width: max-content;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;

    &:hover {
        color: #2563EB88;

        .icon {
            path {
                stroke: #2563EB88;
            }
        }
    }

    .icon {
        margin-left: 11px;
        width: 20px;
        color: #2563EB;
    }
}

.MuiDialog-container {
    .MuiPaper-root {
        border-radius: 16px;
    }
}

.MuiTooltip-tooltip {
    padding: 12px !important;
    text-align: center;
    background-color: #FFF !important;
    box-shadow: 0px 10px 15px -3px #94A3B81A !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #334155 !important;
    font-family: "Montserrat" !important;
    font-weight: 400 !important;

    .MuiTooltip-arrow {
        color: #FFF;
    }
}

.MuiModal-root {
    .MuiInputBase-input {
        padding: 12px 20px;

        &.MuiInputBase-inputMultiline {
            padding: 0px;
        }
    }
}

.brandColor {
    color: $brand600;
}

.bold {
    font-weight: 700;
}

.commonList {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    list-style-type: none;

    @include for-395 {
        row-gap: 24px;
    }

    & * {
        font-family: "Montserrat", sans-serif;
    }

    .commonListItem {
        display: flex;
        align-items: center;

        column-gap: 24px;

        @include for-395 {
            column-gap: 12px;
        }

        .commonListItemText {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            text-align: left;
            color: $neutral900;

            @include for-395 {
                font-size: 18px;
                line-height: 28px;
            }
        }

        .commonListItemMark {
            box-sizing: border-box;
            width: 52px;
            min-width: 52px;
            height: 52px;
            min-height: 52px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $brand600;
            border-radius: 50%;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: $white;
            text-align: center;

            @include for-395 {
                width: 36px;
                min-width: 36px;
                height: 36px;
                min-height: 36px;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}

.phoneInput.MuiFormControl-root.MuiTelInput-TextField  {
    .MuiInputAdornment-root {
        margin: 0;
    }

    .MuiInputBase-input {
        padding-left: 0;
    }

    .MuiIconButton-root {
        padding: 10px;
        margin-left: 10px;
        margin-right: -6px;

        .MuiTelInput-Flag {
            width: 24px;
            min-width: 24px;
            height: 24px;
            border-radius: 50%;
            overflow: hidden;
        }

        .MuiTelInput-FlagImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
