@import "../../mixins.scss";

.ActualTransactions {
    background-color: #EFF6FF;
    padding: 40px;
    border-radius: 16px;

    @include for-768 {
        padding: 24px;
    }

    @include for-395 {
        padding: 14px;
    }

    .header {
        display: grid;
        grid-template-columns: auto 200px;
        gap: 20px;
        align-items: center;

        @include for-768 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        @include for-395 {
            gap: 18px;
        }

        .text {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: flex-end;
            margin-bottom: 6px;

            @include for-768 {
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: flex-start;
                margin-bottom: 0px;
            }

            @include for-395 {
                gap: 12px;
            }

            .title {
                color: #0F172A;
                font-size: 48px;
                line-height: 48px;
                font-family: "Montserrat";
                font-weight: 600;

                @include for-395 {
                    font-size: 36px;
                    line-height: 40px;
                }
            }

            .date {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 2px;
                color: #334155;

                @include for-768 {
                    margin-bottom: 0px;
                }
            }
        }

        .btnContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;
            margin-bottom: 16px;
            height: 100%;

            @include for-768 {
                display: none;
            }

            @include for-395 {
                display: block;
            }
        }
    }
    
    .subHeader {
        margin-top: 40px;

        @include for-768 {
            display: flex;
        }
        
        .buttonsContainer {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            background-color: #DBEAFE;
            width: fit-content;
            border-radius: 4px;
            padding: 4px;

            @include for-395 {
                width: 100%;
            }

            button {
                color: #334155;
                border: 0px;
                text-transform: none;
                font-size: 14px;
                line-height: 20px;
                width: 192px;
                font-family: "MontserratMedium";
                padding: 12px 20px;

                @include for-395 {
                    font-size: 12px;
                    min-height: 16px;
                    width: 50%;
                    padding: 12px 8px;
                }
    
                &.active {
                    font-family: "Montserrat";
                    font-weight: 600;                    
                    color: #2563EB;
                    background-color: #FFF;
                }
            }
        }

        .btnContainer {
            display: none;
            align-items: flex-start;
            justify-content: flex-end;
            flex: 1;

            @include for-768 {
                display: flex;
            }

            @include for-395 {
                display: none;
            }
        }
    }

    .blocks {
        margin-top: 28px;

        @include for-768 {
            margin-top: 20px;
        }
    }
}