.AdminSearchTagsPage {
    .content {
        text-align: left;

        .buttons {
            text-align: left;
            margin: 0px 0px 20px;

            .btn {
                a {
                    color: #FFF;
                    text-decoration: none;
                }
            }
        }

        .table {
            min-height: 800px;

            .cell {
                
            }

            .tagCell {
                flex-direction: column;
                gap: 2px;
                align-items: flex-start;
            }
        }
    }
}

.AdminSearchTagEditDialog {
    .MuiFormControlLabel-root {
        margin-left: 0px !important;
    }

    .MuiPaper-root {
        min-width: 800px;
        overflow: hidden;
        min-height: 400px;
    }

    .MuiAutocomplete-popper {
        overflow-x: hidden;
    }

    .row {
        margin-top: 14px;

        &.transshipment {
            display: grid;
            grid-template-columns: 194px auto 194px;
            gap: 10px;
        }

        &.tags {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 10px;
        }
    }
}