@import "../../../mixins.scss";

.FeedbackForm {
    h3 {
        margin-bottom: 40px;
    }

    .row {
        margin-top: 20px;

        @include for-395 {
            margin-top: 12px;
        }

        .label {
            margin-bottom: 6px;
            font-size: 12px;
            line-height: 16px;
            font-family: "MontserratMedium";
            color: #334155;

            .req {
                color: #F87171;
                display: inline;
                margin-left: 6px;
            }
        }

        input {
            padding: 12px 14px;
        }
    }

    .grid-1-1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @include for-768 {
            gap: 12px;
        }

        @include for-395 {
            display: flex;
            flex-direction: column;
            gap: 12px;
            
            div {
                width: 100%;
            }
        }

        .button {
            display: flex;

            @include for-395 {
                width: 100%;
            }

            button.btn {
                flex: 1;
                height: 60px;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    .rules {
        label {
            align-items: flex-start;
            margin: 0px;
            gap: 8px;
            color: #334155;
            font-family: "MontserratMedium";
        }

        .MuiCheckbox-root {
            padding: 0px;
        }

        .ruleLabel {
            font-size: 12px;
            line-height: 16px;
            width: 275px;
            font-weight: 400;
            font-family: "Montserrat";
            letter-spacing: 0px;

            a {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                font-family: "Montserrat";
            }
        }
    }
}