@import "../../../mixins.scss";

.GeneralForm {
    h3 {
        margin-bottom: 24px;
    }

    .row {
        margin-top: 20px;

        @include for-395 {
            margin-top: 12px;
        }

        .label {
            margin-bottom: 6px;
            font-size: 12px;
            line-height: 16px;
            font-family: "MontserratMedium", sans-serif;
            color: #334155;

            .req {
                color: #F87171;
                display: inline;
                margin-left: 6px;
            }
        }

        input {
            padding: 12px 14px;
        }
    }

    .rules {
        label {
            align-items: flex-start;
            margin: 0px;
            gap: 8px;
            color: #334155;
            font-family: "MontserratMedium", sans-serif;
        }

        .MuiCheckbox-root {
            padding: 0px;
        }

        .ruleLabel {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
            letter-spacing: 0;

            a {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
            }
        }
    }

    .btn {
        margin-top: 24px;

        @include for-395 {
            width: 100%;
        }
    }
}
