@import "../../mixins.scss";

.RouteTagsNearCompany {
    margin-top: 20px;

    .tag {
        margin-bottom: 8px;
        font-style: italic;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: #282828;
        letter-spacing: 1px;
    }
}