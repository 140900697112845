@import "../../mixins.scss";
@import "../../colors.scss";

.GetDemo {
  padding: 64px 40px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $brand50;
  overflow: hidden;

  @include for-395 {
    padding: 24px 14px;
  }

  * {
    box-sizing: border-box;
  }

  .GetDemoBlockContent {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 24px;
    row-gap: 40px;

    @include for-768 {
      column-gap: 38px;
    }

    @include for-395 {
      grid-template-columns: 1fr;
    }

    .GetDemoImg {
      height: auto;
      max-width: 433px;
      justify-self: center;

      @include for-768 {
        order: 2;
        max-width: 375px;
      }

      @include for-395 {
        width: 100%;
        padding: 5px 13px;
      }
    }

    .h3 {
      margin-bottom: 12px;
    }

    .text-base {
      margin-bottom: 24px;
      color: $neutral700;
    }

    .GetDemoBlockContainer1 {
      width: 100%;
      max-width: 495px;
      justify-self: left;

      @include for-768 {
        min-width: 340px;
      }

      @include for-395 {
        min-width: unset;
        max-width: unset;
      }
    }
  }
}
