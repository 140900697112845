@import "../../mixins.scss";

.MobileMenu {
    position: fixed;
    top: 80px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 80px);
    z-index: 1000000;
    display: none;

    &.active {
        display: grid;
        grid-template-columns: auto 375px;
    }
    
    .left {
        background-color: #0F172A;
        opacity: 0.4;
        width: 100%;
    }

    .right {
        background-color: #F8FAFC;
        display: flex;
        flex-direction: column;
    }

    .menuItems {
        margin-top: 28px;
        width: 100%;
        flex: 1;

        .menuItem {
            display: flex;
            box-sizing: border-box;
            align-items: center;
            padding: 12px 24px;
            color: #334155;
            text-decoration: none;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;

            &:hover {
                color: #2563EB;
                text-decoration: underline;
            }
    
            &:active {
                color: #2563EB;
                text-decoration: underline;
            }
    
            &.active {
                color: #2563EB;
                text-decoration: underline;
            }

            .menuIcon {
                margin-left: 120px;
                color: #94A3B8;
            }

            &.sub {
                margin-left: 30px;
            }
        }
    }

    .socialLinks {
        display: flex;
        gap: 12px;
        padding: 28px 32px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
    }

    .new {
        width: 26px;
        height: 18px;
        margin-right: 6px;
    }

    .langContainer {
        display: none;

        @include for-395 {
            display: flex;
            margin-top: 28px;
            padding: 0px;
        }
    }
}