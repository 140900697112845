@import "../../mixins.scss";

.MainPage {
    overflow-x: hidden;

    h2 {
        font-family: "Montserrat";
        font-weight: 600;        
        font-size: 48px;
        line-height: 48px;
        color: #0F172A;

        @include for-395 {
            font-size: 36px;
            min-height: 40px;
        }
    }

    h1 {
        padding: 120px 0 16px;
        text-align: center;
        font-family: "Montserrat";
        font-weight: 600;    
    }

    .content {
        margin: 0px auto 0px;
        width: 1216px;

        @include for-768 {
            width: 684px;
        }

        @include for-395 {
            width: 328px;
        }

        .searchBar {
            margin: 75px 0px 90px;
            position: relative;
            height: 460px;

            @include for-768 {
                height: fit-content;
            }
    
            .textBlock {
                position: relative;
                z-index: 300;
                padding: 25px 0px 0px;
                left: -1100px;
                opacity: 0;
                transition: left 1s 0.3s ease-in, opacity 0.5s 0.3s linear;

                @include for-768 {
                    left: -900px;
                }

                @include for-395 {
                    left: -440px;
                }
                
                > .title {
                    color: rgba(18, 91, 202, 0.8);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #334155;
                }

                > .description {
                    font-family: "Montserrat";
                    font-weight: 600;                    
                    margin-top: 20px;
                    font-size: 48px;
                    line-height: 48px;
                    color: #0F172A;
                    width: 620px;

                    @include for-395 {
                        font-size: 35px;
                        line-height: 40px;
                        width: auto;
                    }
                }

                .search {
                    width: 910px;
                    margin-top: 40px;

                    @include for-768 {
                        width: 100%;
                    }

                    @include for-395 {
                        margin-top: 60px;
                    }

                    .tabs {
                        display: flex;
                        gap: 0px;


                        .tab {   
                            cursor: pointer;                         
                            color: #334155;
                            font-size: 14px;
                            line-height: 20px;
                            font-family: "Montserrat";
                            padding: 12px 20px;
                            background-color: #FEFEFE;
                            border: 1px solid #E2E8F0;
                            border-bottom-width: 0px;
                            border-radius: 16px 16px 0px 0px;
                            box-sizing: border-box;

                            &.active {
                                background-color: #EFF6FF;
                                border: 2px solid #2563EB;
                                border-bottom-width: 0px;
                                font-family: "Montserrat";
                                font-weight: 600;                            
                            }
                        }
                    }
                }
            }

            .imgBlock {
                position: absolute;
                top: 0px;
                right: -700px;
                z-index: 200;
                opacity: 0;
                transition: right 1s 0.3s ease-in, opacity 0.5s 0.3s linear;

                @include for-768 {
                    top: 18px;
                    right: -500px;
                }

                @include for-395 {
                    top: 147px;
                    right: -320px;
                }

                > img {
                    width: 516px;
                    height: 460px;

                    @include for-768 {
                        width: 352px;
                        height: 314px;
                    }

                    @include for-768 {
                        width: 287px;
                        height: 256px;
                    }
                }
            }
        }

        .actualTransactions {
            margin-top: 54px;
            position: relative;
            top: 200px;
            transition: top 1s 0.3s ease-in;

            @include for-768 {
                margin-top: 34px;
            }

            @include for-395 {
                margin-top: 60px;
            }
        }

        .profits {
            margin-top: 200px;
            position: relative;
            top: 200px;
            opacity: 0;
            transition: top 1s linear, opacity 0.5s linear;

            @include for-768 {
                margin-top: 160px;
            }

            .profits_animation {
                
            }

            .blocks {
                margin-top: 60px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;

                @include for-768 {
                    gap: 12px;
                }

                @include for-395 {
                    margin-top: 40px;
                    display: flex;
                    flex-direction: column;
                }

                .block {
                    border-radius: 16px;
                    height: 272px;
                    background-color: #F8FAFC;
                    padding: 40px;
                    box-sizing: border-box;
                    overflow: hidden;
                    position: relative;
                    top: 100px;
                    opacity: 0;

                    @include for-768 {
                        padding: 24px;
                        height: 296px;
                    }

                    @include for-395 {
                        padding: 14px;
                        height: auto;
                    }

                    &.b1 {
                        transition: top 1s 0.4s linear, opacity 0.5s linear;
                    }

                    &.b2 {
                        transition: top 1s 0.8s linear, opacity 0.5s linear;
                    }

                    &.b3 {
                        transition: top 1s 1.2s linear, opacity 0.5s linear;
                    }

                    .icon {
                        width: 44px;
                        height: 44px;

                        img {
                            width: 100%;
                        }
                    }

                    .title {
                        font-family: "Montserrat";
                        font-weight: 600;                        
                        margin-top: 40px;
                        font-size: 24px;
                        line-height: 32px;
                        color: #0F172A;
                    }

                    .description {
                        margin-top: 28px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #334155;
                        font-family: "Montserrat";

                        @include for-395 {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        .exp {
            margin-top: 200px;

            @include for-768 {
                margin-top: 160px;
            }

            .expContainer {
                display: grid;
                grid-template-columns: auto 598px;
                gap: 20px;

                @include for-768 {
                    display: flex;
                    flex-direction: column;
                }
            
                .descriptionContainer {
                    position: relative;
                    top: 750px;
                    transition: top 1s linear;
                    width: 461px;
                    background-color: #FFF;

                    @include for-768 {
                        width: 495px;
                    }

                    @include for-395 {
                        top: 0px;
                        width: auto;
                    }

                    .description {
                        margin-top: 60px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #334155;
                    }

                    .buttonContainer {
                        margin-top: 40px;

                        @include for-395 {
                            margin-top: 28px;
                        }
                    }
                }

                .expBlocks {
                    margin-top: 88px;

                    @include for-768 {
                        margin-top: 32px;
                    }

                    .expBlock {
                        display: flex;
                        gap: 24px;
                        align-items: center;

                        @include for-395 {
                            margin-top: 28px;
                        }

                        .number {
                            font-family: "Montserrat";
                            font-weight: 600;                            
                            font-size: 140px;
                            line-height: 140px;
                            color: #0F172A;
                            display: flex;

                            @include for-395 {
                                font-size: 72px;
                                line-height: 72px;
                            }

                            &.exp1Num {
                                min-width: 200px;

                                @include for-395 {
                                    min-width: 92px;
                                }
                            }

                            &.exp2Num {
                                min-width: 300px;

                                @include for-395 {
                                    min-width: 150px;
                                }
                            }

                            &.exp3Num {
                                min-width: 380px;
                                
                                @include for-395 {
                                    min-width: 190px;
                                }
                            }
                        }

                        .text {
                            width: 210px;
                            font-size: 16px;
                            line-height: 22.4px;
                            color: #334155;
                        }
                    }
                }
            }
        }

        .useful {
            margin-top: 200px;
            position: relative;
            top: 200px;
            transition: top 1s ease-in;

            @include for-768 {
                margin-top: 160px;
            }

            .blocks {
                margin-top: 60px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;

                @include for-768 {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }

                @include for-395 {
                    margin-top: 40px;
                }

                .block {
                    box-sizing: border-box;
                    padding: 40px;
                    height: 346px;
                    background-color: #F8FAFC;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    border-radius: 16px;

                    @include for-768 {
                        height: 266px;
                        padding: 24px;
                    }

                    @include for-395 {
                        height: 218px;
                        padding: 14px;
                    }

                    &.img {
                        padding: 14px;
                        background-color: #FFF;
                        justify-content: center;
                        align-items: center;

                        @include for-768 {
                            order: 4;
                            align-items: flex-start;
                        }

                        @include for-395 {
                            height: 248px;
                        }

                        img {
                            height: 100%;
                        }
                    }

                    .title {
                        font-family: "Montserrat";
                        font-weight: 600;                        
                        font-size: 24px;
                        line-height: 32px;
                        color: #0F172A;
                    }

                    .description {
                        margin-top: 28px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #334155;
                    }
                }
            }
        }

        .partners {
            margin: 200px 72px 200px 0px;
            display: grid;
            grid-template-columns: auto 526px;
            gap: 122px;
            height: 630px;

            @include for-768 {
                margin-top: 160px;
                margin-bottom: 160px;
                display: flex;
                flex-direction: column;
                height: auto;
            }

            @include for-395 {
                gap: 60px;
                margin-right: 0px;
            }

            .partnersText {
                position: relative;
                top: 200px;
                transition: top 1s ease-in;
            }

            .partnersCarousel {
                opacity: 0;
                transition: opacity 1s ease-in;
            }
            
            .description {
                margin-top: 60px;
                font-size: 16px;
                line-height: 24px;
                color: #334155;

                @include for-768 {
                    margin-top: 60px;
                }

                @include for-395 {
                    margin-top: 40px;
                }
            }

            .linkBtn {
                margin-top: 40px;
                height: auto;
            }
        }

    }
}