.AdminLoginPage {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #125BCA;
    align-items: center;
    justify-content: center;

    .shadow {
        background-color: #FFFFFF55;
        border-radius: 40px;
        box-shadow: 0px 0px 40px #FFFFFF77;

        .block {
            margin: 20px;
            padding:  20px 60px 40px;
            width: 400px;
            background-color: #FFF;
            border-radius: 20px;

            .row {
                margin-top: 40px;
            }

            .buttons {
                display: flex;
                justify-content: center;
            }
        }
    }
}