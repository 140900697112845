@import "../../../mixins.scss";

.AdminSettingsPage {
    .settings {
        margin-top: 20px;

        .settingsTable {
            border-collapse: collapse;
            border-radius: 6px;
            box-shadow: 0 0 0 1px #125BCA44;
            overflow: hidden;

            td {
                border: 1px solid #125BCA44;
                padding: 4px 8px;
            }

            thead {
                td {
                    padding: 8px;
                    text-align: center;
                    font-size: 18px;
                    background-color: #125BCA06;
                }

                .actions {
                    width: 78px;
                }

                .code {
                    width: 300px;
                }
            }
        }
    }
}