@import "../../mixins.scss";

.PartnersHorizontal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
    overflow: hidden ;
    position: relative;
    height: 188px;
    width: 328px;

    .block {
        display: flex;
        flex-direction: row;
        gap: 40px;
        position: absolute;
        height: max-content;
        left: 0px;

        &.animate {
            transition-property: left;
            transition-timing-function: linear;
        }

        .partner {
    
            .partnerLogo {
                width: 223px;

                img {
                    max-width: 100%;
                    max-height: 168px;
                    
                    &:hover {
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                    }
                }
            }

            .partnerSite {
                text-align: center;
            }
        }
    }
}