@import "../../../mixins.scss";

.dialog {
    &.DialogFeedback {
        .MuiDialogContent-root {
    
        }

        h2 {

            
        }

        .MuiDialog-paper {
            height: 710px;

            .btn {
                padding: 12px 62px;
            }

            .textarea {
                textarea {
                    height: 100px !important;
                }
            }

            .formFooter {
                font-size: 12px;
                color: #282828;

                a {
                    color: #829FC8;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}