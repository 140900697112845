@import "../../../mixins.scss";

.dialog {
  &.DialogRequestTariff {
    * {
      font-family: "Montserrat", sans-serif;
    }

    .MuiDialogContent-root::-webkit-scrollbar,
    .DialogRequestTariffForm::-webkit-scrollbar {
      width: 0 !important;
      background: transparent;
    }
    .MuiDialogContent-root,
    .DialogRequestTariffForm {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;
    }

    .MuiDialog-paper {
      .MuiDialogContent-root {
        .DialogRequestTariffGrid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 12px;

          @include for-395 {
            grid-template-columns: 1fr;
          }
        }

        .DialogRequestTariffSelect {
          width: 100%;
        }
      }

      .btn {
        width: 100% !important;
        height: 60px;
        font-size: 18px;
        line-height: 28px;
      }

      .DialogRequestTariffLabel {
        margin: 0;
        gap: 8px;

        .ruleLabel {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
        }
      }

      .DialogRequestTariffTextarea {
        textarea {
          padding: 12px 20px;
        }
      }

      .DialogRequestTariffFooter {
        margin-top: 18px;
      }
    }
  }
}

.DialogRequestContact {
  .row {
    margin-top: 20px;

    @include for-395 {
      margin-top: 12px;
    }

    .label {
      margin-bottom: 6px;
      font-size: 12px;
      line-height: 16px;
      font-family: "MontserratMedium";
      color: #334155;

      .req {
        color: #f87171;
        display: inline;
        margin-left: 6px;
      }
    }

    input {
      padding: 12px 14px;
    }
  }

  .grid-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include for-768 {
      gap: 12px;
    }

    @include for-395 {
      display: flex;
      flex-direction: column;
      gap: 12px;

      div {
        width: 100%;
      }
    }

    .button {
      display: flex;

      @include for-395 {
        width: 100%;
      }

      button.btn {
        flex: 1;
        height: 60px;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .rules {
    label {
      align-items: flex-start;
      margin: 0px;
      gap: 8px;
      color: #334155;
      font-family: "MontserratMedium";
    }

    .MuiCheckbox-root {
      padding: 0px;
    }

    .ruleLabel {
      font-size: 12px;
      line-height: 16px;
      width: 275px;
      font-weight: 400;
      font-family: "Montserrat";
      letter-spacing: 0px;

      a {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: "Montserrat";
      }
    }
  }
}
