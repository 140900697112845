@import "../../../mixins.scss";

.AdminDomContentPage {
    .content {
        text-align: left;

        .buttons {
            text-align: left;
            margin: 0px 0px 20px;

            .btn {
                a {
                    color: #FFF;
                    text-decoration: none;
                }
            }
        }

        .table {
            min-height: 800px;

            .color {
                width: 100px;
                height: 30px;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.AdminDomContentEditDialog {
    .MuiInputBase-input.MuiInputBase-inputMultiline {
        padding: 12px 20px !important;
    }

    .MuiPaper-root {
        min-width: 600px;

        .row {
            margin-top: 10px;
        }
    }
}