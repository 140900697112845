.expansionPanel {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #ffffff;
  overflow: hidden;
  transition: all .25s linear;

  &:hover {
    background-color: #F8FAFC;
  }

  .expansionPanelContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: height .25s linear;

    & > * {
      position: relative;
      z-index: -10;
      pointer-events: none;
      opacity: 0;
    }
  }

  &.expanded {
    background-color: #F8FAFC;
    gap: 20px;

    .expansionPanelHeader {
      .expansionPanelExpandIcon {
        transform: rotate(180deg);
      }
    }

    .expansionPanelContent {
      & > * {
        z-index: 1;
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  .expansionPanelHeader {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: unset;
    text-align: left;
    z-index: 5;

    &,
    & * {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      color: #0F172A;
    }
  }

  .expansionPanelExpandIcon {
    width: 44px;
    min-width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
    transition: all .25s linear;
  }

  .expansionPanelContent {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #334155;
  }
}
