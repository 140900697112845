@import "../../mixins.scss";

.SearchResultContainer {
    display: grid;
    grid-template-columns: 290px auto;
    gap: 20px;

    @include for-768 {
        display: block;
    }

    .filter {
        height: fit-content;
    }

    .carouselContainer {
        position: relative;

        .carouselCloseContainer {
            position: absolute;
            top: 12px;
            right: 12px;
            z-index: 1000;

            > span {
                position: relative;
                top: -8px;
                left: -8px;
                color: #94A3B8;
                font-size: 12px;
                line-height: 16px;
            }

            .carouselClose {
                cursor: pointer;

                path {
                    color: #94A3B8;
                }

                &:hover {
                    path {
                        color: #000;
                    }
                }
            }
        }

        .carousel {
            border-radius: 16px;
            margin-bottom: 40px;

            .bullets {
                display: none;
            }

            .banner {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .filter {
        padding: 28px 0px;
        background-color: #FFF;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 28px;

        @include for-768 {
            display: none;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100vw;
            min-height: 100vh;
            z-index: 2100;
            box-sizing: border-box;
        }

        &.open {
            display: block;
        }
    }

    .filterButton {
        display: none;

        @include for-768 {
            display: block;
            position: fixed;
            bottom: 24px;
            left: 0px;
            width: 100%;
            text-align: center;
            z-index: 1010;
            
            button {
                gap: 8px;
            }
        }

        > button {
            width: 1216px;

            @include for-768 {
                width: 684px;
                box-shadow: none;
            }
    
            @include for-395 {
                width: 328px;
            }
        }
    }

    .searchResult {
        margin-top: 0px !important;

        .header {
            .title {
                font-size: 36px;
                line-height: 40px;
                color: #0F172A;
                font-family: "Montserrat";
                font-weight: 600;            
            }

            &.mt {
                margin-top: 40px;
            }
        }
    }
}