@import "../../mixins.scss";

.CarrierPage {
    .breadcrumbs {
        margin-top: 64px;

        @include for-395 {
            .services {
                width: 172px;
            }

            .MuiBreadcrumbs-separator {
                &:nth-child(4) {
                    margin-left: 0px;
                }
            }
        }
    }

    .pageContent {
        margin: 64px auto 200px;
        width: 1216px;
        text-align: left;

        @include for-768 {
            width: 684px;
            margin-bottom: 160px;
        }

        @include for-395 {
            width: 328px;
        }

        .carouselContainer {
            .carousel {

                .bullets {
                    display: none;
                }

                .banner {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .blockContent {
            margin-top: 112px;

            .pageTitle {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 40px 103px;

                @include for-768 {
                    display: flex;
                    flex-direction: column;
                    gap: 60px;
                }

                @include for-395 {
                    gap: 40px;
                }

                h1 {
                    font-size: 48px;
                    line-height: 48px;

                    @include for-395 {
                        font-size: 36px !important;
                        line-height: 40px !important;
                    }
                }

                .description {
                    font-size: 16px;
                    line-height: 24px;
                    color: #334155;
                    width: 495px;

                    @include for-768 {
                        margin-top: 0px;
                    }

                    @include for-395 {
                        width: auto;
                    }
                }

                .empty {

                    @include for-768 {
                        display: none;
                    }
                }
            }
        }

        .profits {
            margin-top: 200px;

            @include for-768 {
                margin-top: 160px;
            }

            h2 {
                font-size: 48px;
                line-height: 48px;

                @include for-395 {
                    font-size: 36px !important;
                    line-height: 40px !important;
                }
            }

            .blocks {
                margin-top: 60px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;

                @include for-768 {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }

                @include for-395 {
                    margin-top: 40px;
                }

                .block {
                    box-sizing: border-box;
                    height: 346px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    border-radius: 16px;
                    padding: 40px;
                    background-color: #F8FAFC;

                    @include for-768 {
                        height: 266px;
                        padding: 24px;
                    }

                    @include for-395 {
                        height: 218px;
                        padding: 14px;
                        width: 100%;
                    }

                    .title {
                        font-size: 24px;
                        line-height: 32px;
                        color: #0F172A;
                        font-family: "Montserrat";
                        font-weight: 600;                    
                    }

                    .description {
                        margin-top: 28px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #334155;
                    }

                    &.img {
                        padding: 16px;
                        background-color: transparent;
                        align-items: center;

                        @include for-768 {
                            height: 288px;
                            padding: 0px;
                        }

                        @include for-395 {
                            height: auto;
                            width: 100%;
                        }

                        img {
                            height: 100%;
                            width: fit-content;

                            @include for-395 {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }

        .formContainer {
            margin-top: 200px;

            @include for-768 {
                margin-top: 160px;
            }
            
            .blocks {
                margin-top: 40px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;

                @include for-768 {
                    margin-top: 60px;
                    display: flex;
                    flex-direction: column;
                    gap: 60px;
                }

                .block {
                    .description {
                        width: 495px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #334155;

                        @include for-395 {
                            display: none;
                        }
                    }

                    .descriptionMobile {
                        width: 326px;
                        display: none;
                        font-size: 16px;
                        line-height: 24px;
                        color: #334155;

                        @include for-395 {
                            display: block;
                        }
                    }

                    .row {
                        margin-bottom: 20px;

                        &.grid-1-1 {
                            gap: 20px;

                            @include for-395 {
                                display: flex;
                                flex-direction: column;

                                >div {
                                    width: 100%;
                                }
                            }
                        }

                        .label {
                            font-family: "Montserrat";
                            font-weight: 600;                            
                            color: #304564;
                            font-style: normal;
                            font-size: 12px;
                            line-height: 16px;
                            margin-bottom: 6px;

                            .req {
                                color: #F87171;
                                display: inline;
                                margin-left: 6px;
                            }
                        }

                        input {
                            padding: 12px 14px;
                        }

                        textarea {
                            height: 134px !important;
                        }

                        .rules {
                            width: 300px;

                            label {
                                align-items: flex-start;
                                margin: 0px;
                                gap: 8px;
                            }
                    
                            .MuiCheckbox-root {
                                padding: 0px;
                            }
                    
                            .ruleLabel {
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 400;
                                font-family: "Montserrat";
                                letter-spacing: 0px;
                    
                                a {
                                    font-size: 12px;
                                    line-height: 16px;
                                    font-weight: 400;
                                }
                            }
                        }

                        .button {
                            width: 300px;
                            margin-top: 12px;

                            @include for-768 {
                                margin-top: 0px;
                            }

                            .btn {
                                font-size: 18px;
                                line-height: 28px;
                                height: 60px;
                            }
                        }

                        &.last {
                            @include for-768 {
                                display: grid;
                                grid-template-columns: auto 312px;
                            }

                            @include for-395 {
                                display: flex;
                                flex-direction: column;
                                gap: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}