@import "../../mixins.scss";

.SearchTags {
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    overflow: hidden;
    height: 38px;

    @include for-395 {
        height: fit-content;
    }

    .searchTag {
        padding: 8px 20px;
        height: 35px;
        background: #EFF6FF;
        border-radius: 24px;
        font-size: 14px;
        line-height: 20px;
        box-sizing: border-box;
        cursor: pointer;
        width: max-content;
        overflow: hidden;
        word-break: break-all;
        border: 1.5px solid #EFF6FF;
        color: #334155;

        &:hover {
            border-color: #2563EB;
        }
    }
}