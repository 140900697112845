@import "../../mixins.scss";

.CollapsedBlock {
    .header {
        display: grid;
        grid-template-columns: auto 19px;
        gap: 8px;
        padding: 10px 0px;
        border-bottom: solid 2px #E2E8F0;
        cursor: pointer;
        margin-left: 40px;
        margin-right: 40px;
        align-items: center;

        @include for-395 {
            margin-left: 24px;
            margin-right: 24px;
        }

        .title {
            font-size: 14px;
            line-height: 20px;
            color: #334155;
            font-family: "Montserrat";
            font-weight: 600;
            
            .iContainer {
                margin-left: 8px;
                display: inline-block;
                position: relative;
                top: 2px;

                svg {
                    width: 12px;
                }
            }
        }

        .arrowContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 19px;
            height: 19px;

            .arrow {
                width: 100%;
                color: #94A3B8;

                &.down {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .content {
        margin-top: 20px;
        max-height: 300px;
        overflow: hidden;

        .MuiFormControlLabel-label {
            color: #334155;
        }

        &.close {
            max-height: 0px !important;
        }

        .cutOffDate {
            width: 100%;
            
            .MuiInputBase-root {
                padding-right: 14px;
            }
        }

        .MuiFormControlLabel-root {
            margin-left: 0px;

            .MuiCheckbox-root {
                padding: 0px;
            }
        }
    }
}