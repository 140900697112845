@import "../../mixins.scss";

.tariffCard {
  width: 100%;
  padding: 64px 40px;
  position: relative;
  background-color: #F8FAFC;
  border-radius: 16px;

  &,
  * {
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
  }

  @include for-395 {
    padding: 24px 14px;
  }

  &.optimal {
    background-color: #EFF6FF;

    .tariffCardOptimalBadge:not(.mobile) {
      display: flex;
    }
  }

  .btn {
    margin-top: 40px;

    @include for-395 {
      margin-top: 24px;
      width: 100%;
    }
  }

  .tariffCardOptimalBadge {
    height: fit-content;
    width: fit-content;
    padding: 6px 12px;
    display: none;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 4px;
    background-color: #DCFCE7;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #334155;

    svg {
      color: #16A34A;
    }

    &:not(.mobile) {
      position: absolute;
      top: 20px;
      right: 20px;

      @include for-395 {
        display: none !important;
      }
    }

    &.mobile {
      display: none !important;

      @include for-395 {
        display: flex !important;
      }
    }
  }

  .tariffCardTitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include for-395 {
      flex-wrap: wrap-reverse;
      row-gap: 12px;
    }

    .tariffCardTitle {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: #0F172A;
    }
  }

  .tariffCardOldPrice {
    height: 24px;
    margin: 16px 0 0;
    text-decoration: line-through;

    @include for-395 {
      margin: 0;
    }
  }

  .tariffCardPrice small,
  .tariffCardOldPrice {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #94A3B8;
  }

  .tariffCardPrice {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
  }

  .tariffCardInfo {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #94A3B8;
    margin-top: 4px;
  }

  .tariffCardOptions {
    padding: 40px 0 0;
    margin: 40px 0 0 ;
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style-type: none;
    border-top: 1.5px solid #E2E8F0;

    @include for-395 {
      padding: 24px 0 0;
      margin: 24px 0 0;
    }
  }

  .tariffCardOptionsItem {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    svg {
      width: 32px;
      min-width: 32px;
      height: 32px;
      padding: 4px;
      border-radius: 50%;
      background-color: white;
    }

    &.optionsOn {
      color: #334155;

      svg {
        color: #2563EB;
      }
    }

    &.optionsOff {
      color: #94A3B8;
      text-decoration: line-through;
    }
  }

  .reset {
    border: 1.5px solid #E2E8F0;

    &:hover {
      border: 1.5px solid #E2E8F0;
      background: linear-gradient(0deg, #E2E8F0, #E2E8F0), linear-gradient(0deg, #F8FAFC, #F8FAFC);
    }
  }
}

