@import "../../mixins.scss";

.RouteTags {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;

    .tag {
        font-size: 14px;
        padding: 4px 10px;
        color: #282828;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        &.start {
            background-color: #FEEEEE;
        }

        &.days {
            background-color: #EBF4FD
            ;
        }

        .circle {
            width: 10px;
            
            &.red {
                color: #F25C5C;
            }
        }
    }
}