@import "../../mixins.scss";

.SearchResultRouteNear {
    &.block {
        margin-top: 24px;
        background-color: #FFF;
        display: grid;
        grid-template-columns: auto 330px;
        border-radius: 16px;
        box-shadow: 0px 4px 6px -2px #94A3B80D;

        @include for-768 {
            display: flex;
            flex-direction: column;
        }

        @include for-395 {
            display: none;
        }

        .left {
            display: flex;
            flex-direction: column;
            padding: 40px;
            position: relative;
            border-right: 1px solid #E2E8F0;

            @include for-768 {
                border-right-width: 0px;
                border-bottom: 1px solid #E2E8F0;
                padding: 24px;
            }

            .verticalWay {
                display: none;
                position: relative;

                &.exact {
                    margin-bottom: 70px;
                }
            }

            .route {
                display: grid;
                grid-template-columns: 150px auto 150px;
                gap: 24px;

                &.near {
                    grid-template-columns: max-content max-content auto max-content max-content;
                }

                .mobileWayIcon {
                    display: none;

                    .iconCircle {
                        width: 10px;
                        margin: 0px 7px;

                        &.black {
                            color: #404040;
                        }
                    }
                }

                .from {
                    margin-top: 8px;
                    position: relative;

                    &.noLeftNear {
                        margin-left: -24px;
                    }

                    &.near {
                        .city {
                            font-size: 14px;
                            line-height: 130%;
                            color: #829FC8;
                            font-weight: bold;
                            margin-top: 16px;
                            position: relative;
                        }

                        .distance {
                            color: #829FC8;
                            margin-top: 8px;
                            font-size: 14px;
                            position: relative;

                            .arrow {
                                position: absolute;
                                color: #829FC8;
                                width: 14px;
                                top: -4px;
                                margin-left: 2px;
                            }
                        }

                        .mobileWayIcon {
                            .arrow {
                                color: #a0a0a0;
                                z-index: 20;
                                position: relative;
                            }

                            .land {
                                position: absolute;
                                width: 50px;
                                height: 4px;
                                top: 10px;
                                left: 11px;
                                z-index: 10;
                                background-color: #FFF;
                                background-image: url("../../assets/imgs/lineLand.png");
                            }
                        }
                    }
                }

                .path {

                    .countOfDay {
                        font-size: 12px;
                        text-align: center;
                        color: #2563EB;
                    }

                    .way {
                        margin-top: 0px;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        justify-content: center;
                        height: 14px;
                        background-repeat: repeat-x;
                        background-position-y: 4px;
                        background-image: url("../../assets/icons/line.svg");
                        position: relative;

                        &.land {
                            background-image: url("../../assets/imgs/lineLand.png");

                            .arrowFrom {
                                color: #a0a0a0;
                            }

                            .circleTo {
                                color: #a0a0a0;
                            }
                        }

                        &.mt {
                            margin-top: 5px;
                        }

                        .rail {
                            background-image: url("../../assets/icons/railLine.svg");
                            height: 14px;
                            background-repeat: repeat-x;
                            background-position-y: 4px;
                        }

                        .arrowFrom {
                            position: absolute;
                            left: -10px;
                            top: -6px;
                            color: #829FC8;
                            width: 8px;
                        }

                        .arrowTo {
                            position: absolute;
                            right: -10px;
                            top: -6px;
                            color: #829FC8;
                        }

                        .circle {
                            width: 8px;
                            color: #2563EB;

                            &.from {
                                position: absolute;
                                left: -4px;
                                top: -7px;
                                margin: 0px;
                                padding: 0px;
                            }

                            &.to {
                                position: absolute;
                                right: -4px;
                                top: -7px;
                                margin: 0px;
                                padding: 0px;
                            }

                            &.black {
                                color: #404040;
                            }
                        }

                        .circleTo {
                            position: absolute;
                            right: -10px;
                            top: -6px;
                            width: 8px;
                            color: #829FC8;
                        }
                    }

                    .station, .city {
                        position: relative;
                        text-align: center;
                    }

                    .station {
                        margin-top: 10px;
                    }
                }

                .to {
                    margin-top: 8px;
                    position: relative;

                    &.near {
                        .city {
                            margin-top: 16px;
                            font-size: 14px;
                            line-height: 130%;
                            color: #829FC8;
                            font-weight: bold;
                        }

                        .distance {
                            color: #829FC8;
                            margin-top: 8px;
                            font-size: 14px;
                            position: relative;
                            padding-left: 18px;

                            .arrow {
                                position: absolute;
                                color: #829FC8;
                                width: 14px;
                                top: -4px;
                                left: 0px;
                            }
                        }

                        .iconCircle {
                            color: #a0a0a0;
                            position: relative;
                            z-index: 20;
                        }

                        .land {

                        }
                    }
                }

                .mobileRailWay {
                    display: none;
                    background-image: url("../../assets/imgs/railLine_v.png");
                    background-repeat-x: no-repeat;
                    position: absolute;
                    height: 74%;
                    width: 3px;
                    left: -19px;

                    &.from {
                        top: 14px;
                    }

                    &.to {
                        top: -76%;
                    }
                }
            }

            .services {
                margin-top: 28px;
            }

            .tariffTypes {
                margin-top: 28px;
            }

            .detailsBtn {
                color: #94A3B8;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                margin-top: 40px;
                cursor: pointer;

                @include for-768 {
                    margin-top: 24px;
                }

                @include for-395 {
                    display: none;
                }

                &:hover {
                    color: #125BCA;
                }

                .arrow {
                    position: relative;
                    top: 8px;
                }
            }

            &.vertical {
                .detailsBtn {
                    position: relative;
                }
            }
        }

        .right {
            position: relative;
            padding: 40px;

            @include for-768 {
                padding: 24px;
            }
        }

        .rightBlock {
            display: flex;
            flex-direction: column;
            height: 100%;

            @include for-768 {
                display: grid;
                grid-template-columns: auto 242px 192px;
                gap: 28px;
            }

            .comapny {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                align-items: flex-start;
                gap: 10px;

                .companyContainer {

                    @include for-768 {
                        word-wrap: break-word;
                        width: 100%;
                    }
                }

                img {
                    height: 24px;

                    @include for-768 {
                        height: auto;
                        width: 100%;
                    }
                }

                .name {
                    font-size: 16px;
                    line-height: 24px;
                    color: #334155;
                    font-family: "Montserrat";
                    font-weight: 600;
                }

                .site {
                    display: none;

                    .link {
                        text-transform: none;
                    }

                    .arrow {
                        margin-left: 4px;
                        width: 14px;
                        transform: rotate(-45deg);
                    }
                }
            }

            .soccocContainer {
                flex: 1;
                display: flex;
                align-items: flex-end;
                margin-top: 36px;

                &.empty {
                    margin-top: 28px;
                }

                @include for-768 {
                    margin-top: 0px;
                    align-items: flex-start;
                }
            }

            .application {
                margin-top: 28px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                gap: 12px;

                @include for-768 {
                    margin-top: 0px;
                    justify-content: flex-start;
                }

                button {
                    width: 100%;
                    height: 44px;
                    font-size: 14px;
                    text-transform: none;
                    font-family: "Montserrat";
                    font-weight: 600;

                    &.booking {
                    }

                    &.request {
                        border-color: #E2E8F0;
                        color: #334155;
                    }
                }
            }
        }

        .desktopRouteTag {
            margin-bottom: 26px;
        }

        .mobileRouteTag {
            display: none;
        }

        .date {
            display: grid;
            grid-template-columns: 14px auto;
            gap: 8px;
            align-items: center;
            font-size: 14px;
            line-height: 130%;
            position: relative;

            .text {
                color: #334155;
                font-size: 14px;
                line-height: 20px;
                margin-top: 2px;
            }
        }

        .station {
            margin-top: 8px;
            font-size: 16px;
            line-height: 24px;
            font-family: "Montserrat";
            font-weight: 600;
            color: #334155;
        }

        .city {
            margin-top: 8px;
            font-size: 14px;
            line-height: 130%;
            color: #829FC8;
        }
    }

    .landingOver, .landingOverTablet {
        margin-top: 28px;
        border-radius: 4px;
        width: fit-content;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
        padding: 8px 12px;
        background-color: #FEF2F2;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        @include for-768 {
            display: none;
        }

        @include for-395 {
            display: flex;
        }

        > svg {
            width: 16px;
            height: 16px;
        }
    }

    .landingOverTablet {
        display: none;
        margin-top: 0px;
        margin-bottom: 24px;

        @include for-768 {
            display: flex;
        }

        @include for-395 {
            display: none;
        }
    }
}

.servicesTariffContainer {
    @include for-768 {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 20px;
    }

    .iContainer {
        @include for-395 {
            display: none;
        }
    }

    .RouteService {
        .title {
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #334155;
        }

        .blocks {
            margin-top: 12px;
            display: flex;
            gap: 20px;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            .block {
                border-radius: 4px;
                display: flex;
                gap: 8px;
                padding: 6px 12px;
                justify-content: flex-start;
                align-items: center;
                color: #334155;
                font-size: 14px;
                line-height: 20px;

                .iContainer {
                    display: flex;
                }
            }
        }
    }

    .RouteTariffType {
        .title {
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #334155;
        }

        .buttons {
            margin-top: 12px;

            .MuiButtonGroup-root {
                button {
                    border-color: #E2E8F0;
                    color: #94A3B8;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: uppercase;
                    padding: 12px 20px;
                    margin: 0px;
                    display: flex;
                    gap: 8px;
                    justify-content: flex-start;
                    align-items: center;

                    @include for-395 {
                        padding: 12px;
                    }

                    &:hover {
                        border-color: #2563EB88;
                    }

                    &.active {
                        font-family: "Montserrat";
                        font-weight: 600;
                        color: #334155;
                        border: 2px solid #2563EB;
                        background-color: #EFF6FF;
                    }
                }
            }
        }
    }
}

.SocCocPrice {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include for-395 {
        gap: 10px;
    }

    .title {
        font-size: 12px;
        line-height: 16px;
        color: #334155;
        font-family: "Montserrat";
        font-weight: 600;
    }

    .prices {
        margin-top: 12px;

        .price {
            font-size: 12px;
            line-height: 16px;
            color: #334155;
            font-family: "Montserrat";
            font-weight: 600;

            > span {
                color: #2563EB;
            }
        }
    }
}

.verticalRoute {
    display: none;

    @include for-395 {
        display: block !important;
    }

    &.open {
        display: block;
    }
}
