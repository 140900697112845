@import "../../mixins.scss";
@import "../../colors.scss";

.CardText {
  padding: 40px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 28px;
  background-color: $neutral50;
  border-radius: 16px;

  @include for-768 {
    width: auto;
  }

  @include for-395 {
    padding: 14px;
    gap: 20px;
  }

  & * {
    font-family: 'Montserrat', sans-serif;
  }

  .CardTitle {
    font-size: 24px;
    line-height: 32px;
    color: $neutral900;
  }

  .CardSubtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $neutral700;
  }
}
