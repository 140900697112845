@import "../../mixins.scss";

.SearchBar3 {
    background: #FFFFFF;
    padding: 38px 38px 38px 18px;
    display: grid;
    gap: 10px;
    grid-template-columns: auto 18px auto 170px;
    align-items: center;
    position: relative;
    border: 2px solid #2563EB;
    border-radius: 0px 16px 16px 16px;
    box-sizing: border-box;

    @include for-768 {
        padding: 22px 14px;
    }

    @include for-395 {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    &.noSearchButton {
        grid-template-columns: auto 18px auto;

    }

    .MuiInputBase-root { 
        padding: 5px !important;
    }

    .from {
        
        @include for-395 {
            width: 100%;
        }
    }

    .switchContainer {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;

        @include for-395 {
            width: 100%;
            margin-top: -4px;
            margin-bottom: -4px;
            margin-left: 10px;

            >img {
                transform: rotate(90deg);
            }
        }
    }

    .to {
        
        @include for-395 {
            width: 100%;
        }
    }

    .space {
        display: none;
    }

    .divider {
        color: #E6E6E6;
        font-size: 30px;
    }

    .date {

    }

    .accentBorder {
        position: absolute;
        width: 80%;
        bottom: 0px;
        border-bottom: 4px solid #125BCA;
    }

    .buttonContainer {
        width: 100%;
        box-sizing: border-box;

        @include for-395 {
            margin-top: 12px;
        }

        .btn {
            width: 100%;

            &.Mui-disabled {
                background-color: #2563EB;
                color: #FFF;
            }
        }
    }

    .noBorders {
        fieldset {
            border: 0px;
        }
    }

    .MuiAutocomplete-popper {
        z-index: 1000000 !important;
    }

    .disabled {
        &.switchContainer {
            opacity: 0.3;
            cursor: default;
        }
    }

    img {
        &.disabled {
            opacity: 0.3;
        }
    }

    .MuiAutocomplete-root {
        .MuiAutocomplete-popupIndicator {
            background-color: #FFF;
        }

        &:hover, &.Mui-focused {
            .MuiAutocomplete-endAdornment {
                background-color: #FFF;
            }
        }
    }
}