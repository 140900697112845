@import "../../../mixins.scss";

.RequestLogPage {
    .content {
        .tabContent {
            margin-top: 20px;

            .buttons {
                margin-bottom: 20px;
                
                .btn {
                    a {
                        color: #FFF;
                        text-decoration: none;
                    }
                }
            }
        }

        .table {
            min-height: 800px;

            .datetime {
                display: flex;
                flex-direction: column;
            }
        }
    }
}