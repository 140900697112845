@import "../../mixins.scss";

.SuccessPage {
  .pageContent {
    margin: 144px auto 180px;
    max-width: 1172px;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    column-gap: 64px;

    @include for-768 {
      max-width: 598px;
      margin: 144px auto;
      grid-template-columns: 1fr;
      justify-items: center;
      row-gap: 64px;
    }

    @include for-395 {
      margin: 112px auto;
      max-width: 328px;
      row-gap: 40px;
    }
  }

  &,
  * {
    box-sizing: border-box !important;
    font-family: "Montserrat", sans-serif !important;
  }

  & > * {
    align-self: center;
  }

  .img {
    width: 100%;
    max-width: 598px;
    margin: 27px 0;

    @include for-395 {
      margin: 15px 0;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  article {
    height: fit-content;
    max-width: 510px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include for-768 {
      grid-row: 1 / 2;
    }
  }

  .SuccessBlockTitle {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    color: #0F172A;

    @include for-395 {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .SuccessBlockText {
    margin-top: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #334155;
  }

  .MuiButtonBase-root.SuccessBlockBtn {
    margin-top: 28px;
    display: flex;
    flex: 1;
    height: 60px;
    font-size: 18px;
    line-height: 28px;

    @include for-395 {
      width: 100%;
    }
  }
}
