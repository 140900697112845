.Loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000cc;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    z-index: 10000000;

    .loaderContent {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        background-color: #FFF;
        padding: 10px;
        border-radius: 20px;
        box-shadow: 0px 0px 16px 6px #00000066;

        .text {
            align-items: center;
        }
    }
}