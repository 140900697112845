@import "../../mixins.scss";

.AdminMenu {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    flex: 1;

    .menuItem {
        text-decoration: none;
        color: #233856;
        padding: 10px 10px 10px 30px;

        &.active {
            background-color: #FFF;
        }
    }

    .devider {
        margin: 6px 30px;
        border-bottom: 1px solid #00000022;
    }
}