.AdminMailTemplates {

    .tabsContent {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #00000010;
        padding: 40px;
    }

    .mailTemplate {
        box-shadow: 0px 0px 10px #00000088;
    }

    .gap-20 {
        gap: 20px;
    }

    .tabs {
        .MuiTabs-flexContainer {
            flex-wrap: wrap;
        }

        .MuiTabs-indicator {
            display: none;
        }

        .MuiTab-root {
            position: relative;

            &:after {
                content: '';
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
                scale: 0;
                background-color: transparent;
                transition: all .25s linear;
            }
        }

        .Mui-selected {
            position: relative;

            &:after {
                scale: 1;
                background-color: #1976d2;
            }
        }
    }
}
