@import "../../../../mixins.scss";

.AdminSearchCarouselPage {

    .buttons {

    }

    .slides {
        margin-top: 20px;
        border: 1px solid #125BCA44;
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;
        
        .slide {
            display: grid;
            grid-template-columns: 80px 200px auto 200px 200px 200px;
            gap: 4px;
            border-bottom: 1px solid #125BCA44;
            box-sizing: border-box;

            &.header {
                div {
                    font-weight: bold;
                    justify-content: center;
                }
            }

            > div {
                padding: 8px;
                display: flex;
                align-items: center;
                overflow: hidden;
                height: 40px;
                border-right: 1px solid #125BCA44;

                > div {
                    height: 100%;
                    overflow: hidden;
                }
            }

            .sort {
                opacity: 0.6;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }

            .img {
                width: 100%;
                cursor: pointer;
            }

            &.noBorders {
                border-width: 0px;
            }

            &.drag {
                opacity: 0.2;
            }

            &.dragBottom {
                opacity: 0.2;
            }
        }
    }
}

.EditCarouselSlideForm {
    .MuiDialog-paper {
        min-width: 800px;
        width: 800px;
    }

    .row {
        margin: 10px 0px 30px;

        &.file {
            display: grid;
            grid-template-columns: auto 180px;
            gap: 10px;

            .uploadBtn {
                height: 40px;
            }
        }
    }
}