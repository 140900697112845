.MainLayout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .children {
        flex: 1;

        &.fixedHeader {
            margin-top: 64px;
        }
    }
}