@import "../../mixins.scss";

.AnalyticsPage {
  * {
    font-family: "Montserrat", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  .GetDemo {
    margin-top: 20px;
  }

  .breadcrumbs {
    margin-top: 64px;
  }

  .blockContent {
    margin-top: 112px;
    display: flex;
    flex-direction: column;
    row-gap: 200px;

    @include for-768 {
      row-gap: 160px;
    }

    @include for-395 {
      margin-top: 80px;
    }
  }

  .pageContent {
    margin: 64px auto 200px;
    width: 1216px;
    text-align: left;

    @include for-768 {
      width: 684px;
      margin-bottom: 160px;
    }

    @include for-395 {
      width: 328px;
    }

    .title {
      font-size: 48px;
      line-height: 1;
      color: #0F172A;

      @include for-395 {
        font-size: 36px;
        line-height: 40px;
        width: auto;
      }
    }

    .gridType1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;

      & > *:first-child {
        max-width: 495px;
      }

      @include for-768 {
        grid-template-columns: 1fr;
        column-gap: 0;
        //column-gap: 28px;
        //column-gap: 60px;
        //gap: 80px;
      }

      @include for-395 {
        width: auto;
        //column-gap: 40px;
        //gap: 60px;
      }
    }

    .blockSection1 {
      position: relative;

      &.gridType1 {
        @include for-768 {
          gap: 80px;
        }

        @include for-395 {
          gap: 60px;
        }
      }

      .blockContainer1 {
        position: sticky;
        top: 100px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 60px;

        @include for-768 {
          position: static;
          top: 0;
        }

        @include for-395 {
          gap: 40px;
        }
      }

      .lHeight {
        height: 48px;
      }

      .btn {
        margin-top: 40px;
      }

      .text {
        font-size: 24px;
        line-height: 32px;
        color: #0F172A;
        font-weight: 600;

        @include for-395 {
          font-size: 20px;
          line-height: 28px;
        }
      }

      .cardList1 {
        margin: 108px 0 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 16px;
        line-height: 24px;
        color: #334155;
        list-style-type: none;

        @include for-768 {
          margin: 0;
        }

        .cardListItem1 {
          display: flex;
          flex-direction: column;
          gap: 40px;
          background-color: #F8FAFC;
          border-radius: 16px;
          padding: 40px;

          @include for-768 {
            padding: 14px;
            width: auto;
          }
        }

        .cardListImg1 {
          height: 44px;
          width: 44px;
          min-width: 44px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          border-radius: 50%;
          background-color: #ffffff;
          color: #94A3B8;
        }

        .cardListTitle1 {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    .blockSection2 {
      .title {
        max-width: 598px;
      }
    }

    .blockSection3 {
      .title {
        text-align: center;

        @include for-768 {
          text-align: left;
        }
      }
    }

    .blockContainer2 {
      padding-bottom: 35px;

      @include for-768 {
        padding-bottom: 25px;
      }

      &.gridType1 {
        @include for-768 {
          gap: 60px;
        }

        @include for-395 {
          gap: 40px;
        }
      }

      .description {
        font-size: 16px;
        line-height: 24px;
        color: #334155;

        @include for-768 {
          width: 504px;
          margin-top: 0px;
        }

        @include for-395 {
          width: auto;
        }
      }

      .btn {
        margin-top: 40px;
        text-decoration: none;
      }

      .img {
        width: 100%;
        padding: 35.5px 48px 0 48px;
        box-sizing: border-box;

        @include for-768 {
          padding: 25px 71px 0 71px;
        }

        @include for-395 {
          padding: 23px 0 0;
        }

        svg {
          width: 100%;
          height: auto;
        }
      }
    }

    .blockContainer2 {
      margin-top: 60px;

      @include for-395 {
        margin-top: 40px;
      }
    }

    .blockContainer3 {
      display: flex;
      flex-direction: column;
    }

    .blockContainer4 {
      margin-top: 60px;
    }
  }

  .expansionPanelWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .buttonGroup {
    margin: 60px auto 40px auto;

    @include for-768 {
      margin: 60px 0 40px 0;
    }

    button {
      border-width: 1.5px;
      border-style: solid;
      border-color: #E2E8F0;
      font-size: 14px;
      line-height: 20px;
      color: #94A3B8;
      font-weight: 400;
      padding: 10.5px 20px !important;
      margin: 0px !important;
      box-sizing: border-box;

      &:hover {
        border-color: #E2E8F0;
      }

      &.active {
        border: 1.5px solid #2563EB;
        background-color: #EFF6FF;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
        font-weight: 600;
      }
    }
  }

  .tariffCardWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include for-768 {
      flex-direction: column;
    }
  }

  .demoBlock {
    padding: 40px;
    position: relative;
    background-color: #F8FAFC;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin-top: 20px;

    @include for-395 {
      padding: 24px 14px;
      gap: 12px;
    }
  }

  .demoBlockLink {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2563EB;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
}
