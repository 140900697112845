@import "../../mixins.scss";

.SearchFilter {
    display: flex;
    flex-direction: column;
    gap: 28px;

    .closeIcon {
        display: none;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }

        @include for-768 {
            display: block;
        }
    }

    .titleFilter {
        display: none;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        text-align: center;
        color: #0F172A;

        @include for-768 {
            display: block;
        }
    }

    .filterBlock {
        padding-left: 40px;
        padding-right: 40px;

        @include for-395 {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .searchLoaderContainer {
        margin: 0px 40px;

        .searchLoader {
            height: 3px;
            width: 0%;
            background-color: #2563EB;

            &.animate {
                width: 100%;
                transition: width 2s linear;
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 28px;

        @include for-768 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

        @include for-395 {
            display: flex;
        }
    }

    .btn {
        width: 100%;
        height: 44px;
    }

    .reset {
        margin-top: -16px;
        border: 1.5px solid #E2E8F0;
        padding: 10.5px 20px !important;

        @include for-768 {
            margin-top: 0px;
        }

        &:hover {
            border: 1.5px solid #E2E8F0;
            background: linear-gradient(0deg, #E2E8F0, #E2E8F0), linear-gradient(0deg, #F8FAFC, #F8FAFC);
        }
    }

    .checkboxContainer {
        display: flex;
        flex-direction: column;
        gap: 0px;

        .checkbox {
            margin: 0px;
            gap: 8px;
            padding: 10px 40px;
            
            @include for-395 {
                padding: 10px 20px;
            }

            &:hover {
                background-color: #F8FAFC;
            }

            &.company {

                .MuiFormControlLabel-label {
                    flex: 1;
                    height: 24px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #334155;
                    overflow: hidden;
                    display: grid;
                    grid-template-columns: 32px auto;
                    gap: 8px;

                    >img {
                        width: 100%;
                    }
                }
            }

            .MuiTypography-root {
                font-size: 14px;
                line-height: 20px;
                font-family: "Montserrat";
                font-weight: 400;
            }
        }
    }
}