@import "../../mixins.scss";

.SearchResultRouteVertical {
    display: grid;
    grid-template-columns: auto 330px;
    margin-top: 24px;
    border-radius: 16px;
    background-color: #FFF;

    @include for-768 {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    @include for-395 {
        margin-top: 12px;
    }

    .topVertical {
        position: relative;
        padding: 40px;

        @include for-768 {
            padding: 24px;
        }

        @include for-395 {
            padding: 14px;
        }

        &.noDetails {
            padding-bottom: 40px;
        }

        .mainBlock {
            display: grid;
            grid-template-columns: auto 244px;

            @include for-768 {
                display: flex;
                flex-direction: column;
            }

            @include for-395 {
            }

            .routeContainer {
                display: flex;
                flex-direction: row;

                .routeContainerBlock {
                    flex: 1;
                }
            }
        }

        .detailsBtn {
            color: #94A3B8;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            margin-top: 40px;
            cursor: pointer;

            &.hasExtRoutes {
                margin-top: 70px;
            }

            &.hasDistance {
                display: none;
            }

            @include for-768 {
                margin-top: 24px;
            }

            @include for-395 {
                display: none;
            }

            &:hover {
                color: #125BCA;
            }

            .arrow {
                position: relative;
                top: 8px;
            }
        }

        .countOfDays {
            transform: rotate(-90deg);
            position: absolute;
            display: flex;
            flex-direction: row;
            gap: 0px 4px;
            margin-left: 0px;
            left: 32px;

            @include for-768 {
                left: 10px;
            }

            .text {
                color: #2563EB;
                font-size: 12px;
                line-height: 16px;
            }

            .arrow {
                margin-top: -2px;
                width: 12px;
                color: #282828;
            }
        }

        .point {
            position: absolute;
            color: #2563EB;
            width: 10px;
            height: 10px;
            margin-left: 26px;
            margin-top: 4px;
            z-index: 100;
        }

        .location {
            position: absolute;
            z-index: 100;
            margin-left: 16px;

            .point {
                margin-left: 10px;
            }

            &.from {
            }

            &.to {
            }

            .text {
                font-size: 14px;
                line-height: 130%;
                color: #334155;
            }

            .locationPoint {
                padding: 0px;
                width: 32px;
                height: 32px;
                color: #334155;
            }
        }

        .blockVertical {
            display: grid;
            grid-template-columns: 20px auto;
            gap: 24px;
            height: 130px;
            margin-left: 16px;

            &.last {
                margin-top: 2px;
                margin-bottom: 20px;
                height: 54px;
            }

            &.withDistance {
                height: 24px;
                margin-top: -14px;
            }

            .routeLine {
                background-image: url("../../assets/icons/line.svg");
                height: 2px;
                width: 130px;
                transform: rotate(90deg) translateX(60px) translateY(50px);
                position: relative;
                top: 16px;

                &.land {
                    background-image: url("../../assets/imgs/lineLand_v.png");

                    .start, .end {
                        color: #2563EB !important;
                    }
                }

                &.rail {
                    background-image: url("../../assets/icons/railLine.svg");

                    .start, .end {
                        color: #2563EB;
                        right: -6px;
                    }
                }

                .start {
                    position: absolute;
                    top: -4px;
                    left: -8px;
                    width: 10px;
                    height: 10px;
                    color: #2563EB;

                    &.arrow {
                        left: -10px;
                        top: -16px;
                        width: 32px;
                        height: 32px;
                        transform: rotate(-90deg);
                    }
                }

                .end {
                    position: absolute;
                    top: -15px;
                    right: -2px;
                    color: #2563EB;
                    width: 32px;
                    height: 32px;
                    transform: rotate(-90deg);
                }
            }

            .routeData {
                position: relative;
                margin-top: 6px;

                .routeDate {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 130%;
                    color: #94A3B8;
                    margin-bottom: 4px;

                    >svg {
                        path {
                            fill: #2563EB;
                        }
                    }

                    .arrow {
                        width: 16px;
                        margin-top: -4px;

                        path {
                            fill: #94A3B8;
                        }
                    }
                }

                .routeTitle {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #334155;
                    margin-bottom: 0px;
                    margin-top: 8px;
                }

                .routeTitleDistance {
                    font-size: 16px;
                    line-height: 24px;
                    color: #94A3B8;
                }

                .routeDistance {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-size: 12px;
                    line-height: 20px;
                    color: #94A3B8;

                    .distance {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        gap: 8px;

                        .arrow {
                            position: absolute;
                            top: -2px;
                            width: 12px;
                        }
                    }
                }

                .routeDescription {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 450;
                    font-size: 14px;
                    line-height: 130%;
                    color: #829FC8;
                }

                .from {

                    &.notFirst {
                        margin-top: -5px;
                    }

                    &.podDistance {
                        margin-top: -6px;
                    }
                }

                .to {
                    position: absolute;

                    &.distance {
                        bottom: -24px;
                    }
                }

                &.transshipment {
                    .routeTitle {
                        display: flex;
                        flex-direction: row;
                        gap: 8px;

                        .ship {
                            margin-top: 0px;

                            path {
                                fill: #2563EB;
                            }
                        }

                        .train {
                            margin-top: 2px;

                            path: {
                                fill: #DC2626;
                            }
                        }

                        .arrow {
                            margin-top: -4px;
                            width: 18px;
                        }
                    }
                }
            }
        }

        .servicesTariffContainer {
            display: flex;
            flex-direction: column;
            gap: 28px;

            @include for-768 {
                margin-top: 28px;
            }
        }
    }

    .bottomVertical {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 40px;
        border-left: 1px solid #E2E8F0;

        @include for-768 {
            flex-direction: row;
            gap: 24px;
            padding: 24px;
            border-left: 0px;
            border-top: 1px solid #E2E8F0;
        }

        @include for-395 {
            padding: 14px;
            flex-direction: column;
        }


        .company {
            flex: 1;
            display: flex;
            flex-direction: column;

            @include for-768 {
                flex-direction: row;
                gap: 8px;
            }

            @include for-395 {
                flex-direction: column;
            }

            .companyContainer {
                flex: 1;

                >img {
                    max-width: 100%;
                    max-height: 20px;
                }

                .name {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .soccocBlock {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            width: 242px;

            .soccocContainer {
                margin-top: 36px;

                @include for-768 {
                    margin-top: 0px;
                }
            }
        }

        .application {
            margin-top: 28px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 12px;
            min-width: 192px;

            @include for-768 {
                margin-top: 0px;
                justify-content: flex-start;
            }

            button {
                width: 100%;
                height: 44px;
                font-size: 14px;
                text-transform: none;
                font-family: "Montserrat";
                font-weight: 600;

                &.booking {
                }

                &.request {
                    border-color: #E2E8F0;
                    color: #334155;
                }
            }
        }
    }

    &.transshipment {
        .routeData {
            .to {
                top: 0px;
            }
        }
    }

    .infoIcon {
        position: relative;
        top: 3px;
    }

    .landingOver, .landingOverTablet {
        margin-top: 12px;
        margin-bottom: 28px;
        border-radius: 4px;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
        padding: 8px 12px;
        background-color: #FEF2F2;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        @include for-768 {
            display: none;
        }

        @include for-395 {
            display: flex;
        }

        > svg {
            width: 16px;
            height: 16px;
        }
    }

    .landingOverTablet {
        display: none;
        margin: 0px;

        @include for-768 {
            display: flex;
        }

        @include for-395 {
            display: none;
        }
    }
}
