.UploadRoutesPage {
    .pageContent {
        margin: 0px;
        text-align: left;

        h1 {
            margin: 0px 0px 40px;
            padding: 0px;
            font-size: 30px;
        }

        .row {
            display: grid;
            grid-template-columns: 340px auto;
            gap: 20px;
            align-items: center;
            margin: 10px 0px;

            .title {
                font-size: 18px;
            }

            .value {
                &>div {
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }
            }
        }

        .buttons {
            margin: 20px 0px 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .btn {
                width: 300px;
            }
        }
    }
}