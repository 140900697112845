@import "../../../mixins.scss";

.dialog {
    &.DialogSubscribeRouteMail {
        .MuiDialogTitle-root {
            max-width: 628px;
        }

        .MuiDialogContent-root {
            
        }

        h2 {
            @include for-395 {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .MuiDialog-paper {
            width: 780px;
            min-width: 780px;
            height: auto;

            @include for-395 {
                width: 370px;
                min-width: 370px;        
            }

            .description {
            }

            .btn {
                padding: 12px 62px;
            }

            .textarea {
                textarea {
                    height: 100px !important;
                }
            }

            .formFooter {
                font-size: 12px;
                color: #282828;

                a {
                    color: #829FC8;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .grid_1-1 {
                background-color: #FFF;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 12px;
                margin-bottom: 12px;

                @include for-395 {
                    display: flex;
                    flex-direction: column;
                }
            }

            .rules {
                label {
                    align-items: flex-start;
                    margin: 0px;
                    gap: 8px;
                    color: #334155;
                    font-family: "Montserrat";
                }
        
                .MuiCheckbox-root {
                    padding: 0px;
                    width: 20px;
                    height: 20px;
                }
        
                .ruleLabel {
                    font-size: 12px;
                    line-height: 16px;
                    width: 274px;
                    font-weight: 400;
                    font-family: "Montserrat";
                    letter-spacing: 0px;
        
                    a {
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 400;
                    }
                }
            }

            .btn {
                margin-top: 0px;
                width: 100%;
                height: 60px;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}