@import "../../mixins.scss";

.SearchPage {
    overflow-x: hidden;

    .pageContent {
        margin: 64px auto 0px;
        text-align: left;

        .searchLoader {
            height: 3px;
            width: 0%;
            background-color: #2563EB;

            &.animate {
                width: 100%;
                transition: width 2s linear;
            }
        }

        .searchBar {
            width: 1216px;
            margin: 0px auto;

            @include for-768 {
                width: 684px;
            }
    
            @include for-395 {
                width: 328px;
            }

            .tabs {
                display: flex;
                gap: 0px;


                .tab {   
                    cursor: pointer;                         
                    color: #334155;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: "Montserrat";
                    padding: 12px 20px;
                    background-color: #FEFEFE;
                    border: 1px solid #E2E8F0;
                    border-bottom-width: 0px;
                    border-radius: 16px 16px 0px 0px;
                    box-sizing: border-box;

                    &.active {
                        background-color: #EFF6FF;
                        border: 2px solid #2563EB;
                        border-bottom-width: 0px;
                        font-family: "Montserrat";
                        font-weight: 600;                    
                    }
                }
            }
        }

        .blueBackground {
            margin-top: 64px;
            padding-bottom: 72px;
            background-color: #EFF6FF;

            @include for-768 {
                padding-bottom: 40px;
            }

            .resultContent {
                width: 1216px;
                margin: 0px auto;
                padding: 40px 0px;

                @include for-768 {
                    width: 684px;
                }
        
                @include for-395 {
                    width: 328px;
                }

                .searchResult {
                    margin-top: 40px;
                }
            }
        }

        .whiteBackground {
            width: 1216px;
            margin: 200px auto;

            @include for-768 {
                position: relative;
                width: 684px;
                margin: 0px auto;
                padding: 200px 0px;
                z-index: 2000;
                background-color: #FFF;
            }
    
            @include for-395 {
                width: 328px;
                padding-bottom: 120px;
            }

            &.noResults {
                margin-top: 120px;

                @include for-768 {
                    margin-top: 0px;
                    padding-top: 120px;
                }

                @include for-395 {
                    padding-top: 80px;
                }
            }

            h2 {
                width: 598px;

                @include for-395 {
                    width: auto;
                }
            }

            .blocks {
                margin-top: 60px;
                display: grid;
                grid-template-columns: 598px auto;
                gap: 20px;

                @include for-768 {
                    display: flex;
                    flex-direction: column;
                }

                .block {
                    height: 346px;

                    @include for-768 {
                        height: 266px;
                    }
                
                    &.text {
                        background-color: #F8FAFC;
                        box-sizing: border-box;
                        border-radius: 16px;
                        padding: 40px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;

                        @include for-395 {
                            padding: 14px;
                        }

                        .title {
                            font-size: 24px;
                            line-height: 32px;
                            color: #0F172A;
                            font-family: "Montserrat";
                            font-weight: 600;                        
                        }

                        .description {
                            font-size: 16px;
                            line-height: 24px;
                            color: #334155;
                            margin-top: 28px;
                        }

                        .linkBtn {
                            margin-top: 28px;
                        }
                    }
    
                    &.img {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include for-768 {
                            justify-content: flex-start;
                        }
    
                        img {
                            height: 100%;
                        }
                    }
                }
            }
        }

        .breadcrumbs { 
            @include for-395 {    
                .services {
                    width: 172px;
                }
        
                .MuiBreadcrumbs-separator {
                    &:nth-child(4) {
                        margin-left: 0px;
                    }
                }
            }
        }


        .otherBtn {
            margin-top: 20px;

            button {
                width: 100%;
                border-color: #E2E8F0;
                font-size: 14px;
                line-height: 20px;
                color: #334155;
                padding: 11px 20px;
            }
        }

        .other {
            height: 0px;
            overflow: hidden;

            &.show {
                height: auto;
            }
        }
    }
}