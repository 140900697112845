@import "../../../mixins.scss";

.dialog {
    &.GetMeMoreInformation {

        .textarea {
            .MuiOutlinedInput-root {
                padding: 12px 20px;
            }

        }
        
        .MuiDialogContent-root {
            
        }

        h2 {
            
        }

        .form {
            padding-top: 10px !important;

            .rules {
                label {
                    align-items: flex-start;
                    margin: 0px;
                    gap: 8px;
                    color: #334155;
                    font-family: "Montserrat";
                }
        
                .MuiCheckbox-root {
                    padding: 0px;
                    width: 20px;
                    height: 20px;
                }
        
                .ruleLabel {
                    font-size: 12px;
                    line-height: 16px;
                    width: 274px;
                    font-weight: 400;
                    font-family: "Montserrat";
                    letter-spacing: 0px;
        
                    a {
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 400;
                    }
                }
            }
    
            .routeError {
                color: #d32f2f;
                font-size: 0.75rem;
                padding: 0px 42px;
                background-color: #FFF;
                position: relative;
                top: -12px;
            }

            .btn {
                margin-top: 0px;
                width: 100%;
                height: 60px;
                font-size: 18px;
                line-height: 28px;
            }
        }

        .grid_1-1 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }

        .MuiDialog-paper {

            @include for-395 {
                
            }

            .btn {
                padding: 12px 62px;
            }

            .textarea {
                textarea {
                    height: 100px !important;
                }
            }

            .formFooter {
                font-size: 12px;
                color: #282828;

                a {
                    color: #829FC8;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.GetMeMoreInformation_select, .GetMeMoreInformation_menu {
    .iconType {
        margin-right: 10px;
        margin-top: -3px;
    }
}