$brand600: #2563EB;
$brand50: #EFF6FF;

$white: #FFFFFF;

$neutral900: #0F172A;
$neutral700: #334155;
$neutral400: #94A3B8;
$neutral50: #F8FAFC;

