@import "../../../mixins.scss";

.AdminTagsPage {
    .content {
        text-align: left;
        width: 1200px;

        .buttons {
            text-align: left;
            margin: 0px 0px 20px;

            .btn {
                a {
                    color: #FFF;
                    text-decoration: none;
                }
            }
        }

        .table {
            min-height: 600px;

            .color {
                width: 100px;
                height: 30px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px 8px;
            }
        }
    }
}

.AdminTagsEditDialog {
    .MuiPaper-root {
        width: 800px;
        max-width: 800px;
    }

    .row {
        margin-bottom: 20px;

        &.grid-1-1-1 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;
        }
    }
}