@import "../../mixins.scss";
@import "../../colors.scss";

.Card {
  padding: 40px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: $neutral50;
  border-radius: 16px;

  @include for-768 {
    width: auto;
  }

  @include for-395 {
    padding: 14px;
  }

  .CardImg {
    height: 44px;
    width: 44px;
    min-width: 44px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: $white;
    color: $neutral400;
  }

  .CardTitle {
    font-size: 24px;
    line-height: 32px;
    color: $neutral900;
  }
}
