.UDialog {
    .MuiDialog-paper {
        min-width: 800px;
        width: 800px;
        overflow: hidden;
        max-height: 600px;

        .MuiDialogContent-root {
            text-align: center;
        }
    }
}