@import "../../../mixins.scss";

.SearchLogPage {
    .content {
        margin: 20px auto 20px;
        width: 1216px;
        text-align: left;

        .buttons {
            text-align: right;
            margin: 0px 0px 20px;

            .btn {
                a {
                    color: #FFF;
                    text-decoration: none;
                }
            }
        }

        .table {
            min-height: 800px;
        }
    }
}