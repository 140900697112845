@import "../../mixins.scss";

.Header {
    border-bottom: 1px solid #E2E8F0;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    background-color: #FFF;

    .headerContainer {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1200;
        width: 1216px;
        padding: 0px;
        margin: 0px auto;
        box-sizing: border-box;

        @include for-768 {
            width: 684px;
        }

        @include for-395 {
            width: 328px;
        }
    }

    &.fixed {
        position: fixed;
        z-index: 2100;
    }

    .headerContent {
        flex: 1;
        width: 100%;
        display: grid;
        grid-template-columns: 140px auto;
        gap: 10px;

        .leftBlock {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            color: #000;

            .logo {
                width: 25px;
                margin-right: 8px;
            }

            .logoTitle {
                margin-top: 6px;
            }
        }

        .centerBlock {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            @include for-768 {
                display: none;
            }
        }

        .rightBlock {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            a {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-decoration: none;
                cursor: default;
                width: 100%;

                &.active {
                    background-color: #125BCA;

                    span {
                        color: #FFF;
                        text-transform: none;
                        font-size: 14px;
                        font-family: "Montserrat";
                        font-weight: 600;                        
                        padding: 12px 20px;
                        letter-spacing: 0.0125em;
                        text-align: center;
                    }
                }

                button {
                    height: 48px;
                }
            }
        }

        .mobileMenu {
            display: none;

            @include for-768 {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 40px;
            }

            .langContainer {
                display: block;

                @include for-395 {
                    display: none;
                }
            }

            .menuContainer {
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    svg {
                        path {
                            stroke: #2563EB;
                        }
                    }

                    .menuText {
                        color: #2563EB;
                    }
                }

                .mobileMenuIcon {
                    display: none;

                    &.active {
                        display: block;
                    }
                }

                .menuText {
                    color: #334155;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
    
}