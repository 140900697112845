@import "../../../mixins.scss";

.dialog {
  &.InfoDialog {
    * {
      box-sizing: border-box;
    }

    .btn {
      font-size: 18px;
      line-height: 28px;
      padding: 16px 24px;
    }

    .MuiDialog-paper {
      max-width: 560px;
      width: 100%;

      @include for-395 {
        max-width: 328px;
      }

      .MuiDialogContent-root {
        margin-top: 28px;
        margin-bottom: 28px;

        @include for-395 {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      color: #304564;
      opacity: 1;
      cursor: pointer;
    }

    .btn {
      width: 100%;
    }

    .MuiDialog-paper .MuiDialogTitle-root {
      margin: 40px 40px 0;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
      text-align: center;

      @include for-395 {
        margin: 24px 24px 0;
      }
    }

    .MuiDialogContent-root {
      margin: 28px 40px;

      @include for-395 {
        margin: 20px 24px;
      }

      .MuiTypography-root {
        max-width: 374px;
        margin: 0 auto;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
      }
    }

    .MuiDialogActions-root {
      margin: 0 40px 40px;
      padding: 0;

      @include for-395 {
        margin: 0 24px 24px;
      }
    }
  }
}
