@import "../../mixins.scss";

.BottomMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .menuItem {
        color: #334155;
        display: flex;
        gap: 10px;
        text-decoration: none;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;

        &:hover {
            color: #125BCAAA;
        }
    }
}