@import "../../../mixins.scss";

.AdminRoutesPage {
    .content {
        text-align: left;

        .buttons {
            text-align: left;
            margin: 0px 0px 20px;

            .btn {
                a {
                    color: #FFF;
                    text-decoration: none;
                }
            }
        }

        .table {
            min-height: 800px;

            .cell {
                
            }

            .tagCell {
                flex-direction: column;
                gap: 2px;
                align-items: flex-start;
            }
        }
    }
}

.AdminRouteEditDialog {
    
    &.MuiDialog-root {
        z-index: 1300 !important;
    }

    .MuiPaper-root {
        min-width: 800px;
        overflow: hidden;
    }

    .MuiAutocomplete-popper {
        overflow-x: hidden;
    }

    .row {
        margin-top: 14px;

        label {
            margin-left: 0px !important;
        }

        .MuiOutlinedInput-root {
            padding-right: 14px;
        }

        &.transshipment {
            display: grid;
            grid-template-columns: 194px auto 194px;
            gap: 10px;
        }

        &.tags {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 10px;
        }

        .cutOffDate, .bookingValidTill {
            .Mui-error {
                color: inherit;

                .MuiOutlinedInput-notchedOutline {
                    border-color: inherit;
                }
            }
        }
    }
}