@import "../../mixins.scss";

.Footer {
    background-color: #EFF6FF;
    z-index: 2000;

    .footerContent {
        width: 1216px;
        margin: 60px auto 100px;
        display: grid;
        grid-template-columns: auto 700px;
        gap: 40px;

        @include for-768 {
            width: 684px;
            display: flex;
            flex-direction: column;
            margin-bottom: 80px;
        }

        @include for-395 {
            width: 328px;
            margin-bottom: 60px;
        }

        .leftBlock {

            .logoContainer {
                display: flex;
                align-items: center;
                gap: 9px;


                .logo {
                    width: 25px;
                }
            }

            .menuContainer {
                margin-top: 80px;

                @include for-768 {
                    display: none;
                }
            }

            .linkContainer {
                margin-top: 48px;
                display: flex;
                gap: 12px;

                @include for-768 {
                    display: none;
                }

                .imgLink {
                    width: 36px;
                }
            }

            .subLinks {
                margin-top: 64px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                @include for-768 {
                    display: none;
                }

                a {
                    font-size: 14px;
                    line-height: 20px;
                    color: #94A3B8;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                        color: #2563EB;
                    }
                }
            }
        }

        .rightBlock {
            margin-top: 68px;

            @include for-768 {
                margin-top: 0px;
            }

            .formContainer {
                border-radius: 16px;
                background-color: #FFF;
                padding: 40px;
                box-shadow: 0px 10px 15px -3px #94A3B81A;


                @include for-768 {
                    padding: 24px;
                }

                @include for-395 {
                    padding: 14px;
                }
            }
            
        }

        .mobile {
            display: none;

            @include for-768 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 28px;
            }

            @include for-395 {
                display: flex;
                flex-direction: column;
                gap: 40px;
            }

            .rightBlock {
                display: flex;
                flex-direction: column;
                height: 100%;

                @include for-395 {
                    gap: 40px;
                }
            }

            .linkContainer {
                display: flex;
                gap: 12px;

                .imgLink {
                    width: 36px;
                }
            }

            .subLinks {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex: 1;
                margin-bottom: 16px;
                gap: 10px;

                @include for-768 {
                    margin-bottom: 0px;
                }

                a {
                    font-size: 14px;
                    line-height: 20px;
                    color: #94A3B8;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                        color: #2563EB;
                    }
                }
            }
        }
    }
}