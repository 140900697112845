@mixin for-768 {
    @media screen and (max-width: 1440px) {
        @content;
    }
}

@mixin for-395 {
    @media screen and (max-width: 768px) {
        @content;
    }
}