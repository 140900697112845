@import "../../mixins.scss";

.AboutPage {
    .breadcrumbs {
        margin-top: 64px;
    }

    .pageContent {
        margin: 64px auto 200px;
        width: 1216px;
        text-align: left;

        @include for-768 {
            width: 684px;
            margin-bottom: 160px;
        }

        @include for-395 {
            width: 328px;
        }

        .shipAnimation {
            height: 224px;
            position: relative;
            overflow: hidden;

            .sky {
                position: absolute;
                top: 33px;
                left: 0px;

                &.animate {
                    transition: left 30s linear;
                }
            }

            .sea {
                
                position: absolute;
                bottom: 46px;
            }

            .ship {
                position: absolute;
                bottom: 27px;
                left: -202px;

                &.animate {
                    transition: left 30s linear;
                }
            }
        }

        h1 {
            font-size: 48px;
            line-height: 48px;
            color: #0F172A;
            margin-top: 112px;

            @include for-395 {
                margin-top: 80px;
            }
        }

        .blockContainer1 {
            margin-top: 60px;
            display: grid;
            grid-template-columns: 495px 495px;
            gap: 123px;

            @include for-768 {
                display: flex;
                flex-direction: column;
                gap: 28px;
            }

            @include for-395 {
                width: auto;
                margin-top: 40px;
            }

            .block1 {
                font-size: 24px;
                line-height: 32px;
                color: #0F172A;
                font-weight: 600;

                @include for-395 {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            .block2 {
                font-size: 16px;
                line-height: 24px;
                color: #334155;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }

        .blockContainer2 {
            margin-top: 80px;
            display: flex;
            gap: 20px;

            @include for-768 {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            @include for-395 {
                margin-top: 60px;
            }
            
            .block {
                background-color: #F8FAFC;
                border-radius: 16px;
                padding: 40px;
                width: 33%;

                .img {
                    height: 44px;
                }

                @include for-768 {
                    padding: 24px;
                    width: auto;
                }

                @include for-395 {
                    padding: 14px;
                }

                .title {
                    margin-top: 40px;
                    font-size: 24px;
                    line-height: 32px;
                    font-family: "Montserrat";
                    font-weight: 600;                    
                    color: #0F172A;
                }

                .description {
                    margin-top: 28px;
                    font-size: 16px;
                    line-height: 24px;
                    color: #334155;
                }
            }
        }

        .blockContainer3 {
            margin-top: 200px;

            @include for-768 {
                margin-top: 160px;
            }

            .title {
                margin-top: 40px;
                font-size: 48px;
                line-height: 48px;
                font-family: "Montserrat";
                font-weight: 600;                
                color: #0F172A;
                width: 504px;

                @include for-395 {
                    font-size: 36px;
                    line-height: 40px;
                    width: auto;
                }
            }

            .blocks {
                margin-top: 60px;
                display: grid;
                grid-template-columns: 504px auto;
                gap: 123px;

                @include for-768 {
                    display: flex;
                    flex-direction: column;
                    gap: 60px;
                }

                @include for-395 {
                    margin-top: 40px;
                }

                .description {
                    font-size: 16px;
                    line-height: 24px;
                    color: #334155;

                    @include for-768 {
                        width: 504px;
                        margin-top: 0px;
                    }

                    @include for-395 {
                        width: auto;
                    }
                }  

                .linkBtn {
                    margin-top: 40px;
                }

                .img {
                    width: 598px;

                    @include for-768 {
                        width: auto;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
        
    }
}