@import "../../../../../mixins.scss";

.TabSearchLog {   
    .dashboard {
        margin-bottom: 20px;

        .filter {
            display: flex;
            gap: 10px;
            align-items: center;

            .date {
                width: 150px;

                .Mui-error {
                    fieldset {
                        border-color: rgba(0, 0, 0, 0.23);
                        border-width: 1px;
                    }
                }
            }
        }

        .result {
            margin-top: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;

            .filterPanels {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;

                .filterPanel {
                    border-radius: 8px;
                    padding: 8px 14px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    &.filterPanel1 {
                        background: #E9F3FD;
                    }

                    &.filterPanel2 {
                        background-color: #829FC8;

                        .text {
                            color: #FFF;
                        }

                        .number {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #FFF;
                        }
                    }

                    .text {
                        font-family: 'MontserratBold';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        color: #304564;
                        text-align: center;
                    }

                    .number {
                        font-family: 'MontserratBold';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 40px;
                        line-height: 49px;
                        color: #304564;
                        text-align: center;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            h4 {
                margin: 0px;
            }

            table {
                width: 100%;
                border-collapse: collapse;

                td {
                    border: 1px solid #E7E7E7;
                    padding: 4px;
                }

                thead {
                    td {
                        font-family: "Montserrat";
                        font-weight: 600;                        
                        color:#A0A0A0;
                        font-size: 10px;
                    }
                }

                tbody {
                    td {
                        font-family: "Montserrat";
                        font-weight: 600;                        
                        font-size: 12px;

                        &.right {
                            text-align: right;
                            width: 60px;
                        }
                    }
                }
            }
        }
    }
    
    .buttons {
        text-align: left;
        margin: 0px 0px 20px;

        .btn-np {
            
            a {
                color: #FFF;
                text-decoration: none;
            }
        }
    }

    .table {
        min-height: 800px;
        text-align: left;
    }
}