@import "../../../mixins.scss";

.AdminLineRatePage {
    .content {
        .buttons {
            margin-bottom: 20px;
        }

        .table {
            min-height: 800px;
        }
    }
}

.AdminUploadLineRateDialog {
    .MuiPaper-root {
        min-width: 800px;
    }

    .row {
        margin-top: 10px;

        &.grid-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }

        &.grid-3 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
        }
    }
}