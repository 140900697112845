@import "../../mixins.scss";

.TopMenu {
    display: flex;
    flex: 1;

    &.noBg {
        .menuItem {
            color: #FFF;
            text-shadow: 0px 0px 2px #00000088;

            &:hover {
                text-shadow: 0px 0px 0px #00000000;
                background-color: #FFFFFF44;
            }
        }
    }

    .menuItem {
        display: flex;
        box-sizing: border-box;
        height: 100%;
        align-items: center;
        padding: 0px 22px;
        color: #334155;
        text-decoration: none;
        font-size: 14px;
        width: max-content;
        cursor: pointer;

        &:hover {
            color: #2563EB;
            text-decoration: underline;
        }

        &:active {
            color: #2563EB;
            text-decoration: underline;
        }

        &.active {
            color: #2563EB;
            text-decoration: underline;
        }

        .menuIcon {
            margin-left: 4px;
            transform: rotate(180deg);

            &.open {
                transform: rotate(0deg);
            }
        }
    }

    .new {
        width: 35px;
        height: 20px;
        margin-right: 10px;
    }

    .langContainer {
        margin-left: 38px;
        display: flex;
        align-items: center;
    }
}

.TopMenu_menu {
    .MuiPaper-root {
        box-shadow: 0px 4px 6px -2px #94A3B80D, 0px 10px 15px -3px #94A3B81A;
        border-radius: 4px;
        margin-top: -20px;

        ul {
            padding: 0px;
        }

        li {
            font-family: 'Montserrat';
            font-style: normal;
            color: #334155;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding: 12px 20px;

            &:hover {
                background-color: #F1F5F9;
            }
        }
    }
}