@import "../../../mixins.scss";

.dialog {
    &.DialogRequestSubscription {
        * {
            font-family: "Montserrat", sans-serif;
        }

        .MuiDialogContent-root::-webkit-scrollbar,
        .DialogRequestSubscriptionForm::-webkit-scrollbar {
            width : 0 !important;
            background: transparent;
        }
        .MuiDialogContent-root,
        .DialogRequestSubscriptionForm {
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            scrollbar-width: none;
        }

        .MuiDialog-paper {
            .MuiDialogContent-root {
                .DialogRequestSubscriptionGrid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 12px;

                    @include for-395 {
                        grid-template-columns: 1fr;
                    }
                }

                .DialogRequestSubscriptionSelect {
                    width: 100%;
                }

                .MuiSelect-select {
                    line-height: 24px;
                }

            }


            .btn {
                width: 100% !important;
                height: 60px;
                font-size: 18px;
                line-height: 28px;
            }

            .DialogRequestSubscriptionLabel {
                margin: 0;
                gap: 8px;

                .ruleLabel {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                }
            }

            .DialogRequestSubscriptionTextarea {
                textarea {
                    padding: 12px 20px;
                }
            }

            .DialogRequestSubscriptionFooter {
                margin-top: 18px;
            }
        }
    }
}

.FeedbackForm {
    h3 {
        margin-bottom: 40px;
    }

    .row {
        margin-top: 20px;

        @include for-395 {
            margin-top: 12px;
        }

        .label {
            margin-bottom: 6px;
            font-size: 12px;
            line-height: 16px;
            font-family: "MontserratMedium";
            color: #334155;

            .req {
                color: #F87171;
                display: inline;
                margin-left: 6px;
            }
        }

        input {
            padding: 12px 14px;
        }
    }

    .grid-1-1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @include for-768 {
            gap: 12px;
        }

        @include for-395 {
            display: flex;
            flex-direction: column;
            gap: 12px;

            div {
                width: 100%;
            }
        }

        .button {
            display: flex;

            @include for-395 {
                width: 100%;
            }

            button.btn {
                flex: 1;
                height: 60px;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    .rules {
        label {
            align-items: flex-start;
            margin: 0px;
            gap: 8px;
            color: #334155;
            font-family: "MontserratMedium", sans-serif;
        }

        .MuiCheckbox-root {
            padding: 0px;
        }

        .ruleLabel {
            font-size: 12px;
            line-height: 16px;
            width: 275px;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
            letter-spacing: 0px;

            a {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}
