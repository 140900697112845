@import "../../../mixins.scss";

.dialog {
    &.DialogRequestFree {

        .MuiDialogContent-root {
            
        }

        h2 {
            font-size: 36px !important;

            
        }

        .MuiDialog-paper {
            max-width: 1200px;
            width: 1200px;
            height: 740px;

            .route {
                background-color: #FFF;

                .routeContent {
                    padding: 14px 24px;
                    display: grid;
                    grid-template-columns: auto 30px auto;
                    align-items: center;
                }

            }

            .grid_1-1 {
                background-color: #FFF;
                padding: 0px 24px 14px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;
                align-items: flex-end;

            }
    
            .routeError {
                color: #d32f2f;
                font-size: 0.75rem;
                padding: 0px 42px;
                background-color: #FFF;
                position: relative;
                top: -12px;
            }

            .btn {
                padding: 12px 62px;
            }

            .textarea {
                textarea {
                    height: 44px !important;
                }
            }

            .formFooter {
                font-size: 12px;
                color: #282828;

                a {
                    color: #829FC8;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.Dialog-request_select, .Dialog-request_menu {
    .iconType {
        margin-right: 10px;
        margin-top: -3px;
    }
}