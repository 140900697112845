@import "../../mixins.scss";

.Partners {
    height: 630px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
    overflow: hidden ;
    position: relative;
    width: 526px;

    .block {
        display: flex;
        flex-direction: column;
        gap: 40px;
        position: absolute;
        height: max-content;

        .partner {
            width: 223px;
    
            .partnerLogo {
                width: 223px;

                img {
                    width: 100%;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);

                    &:hover {
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                    }
                }
            }

            .partnerSite {
                margin-top: 10px;
                text-align: center;
            }
        }

        &.left {
            top: 0px;

            &.animate {
                transition-property: top;
                transition-timing-function: linear;
            }
        }

        &.right {
            bottom: 0px;
            right: 0px;

            &.animate {
                transition-property: bottom;
                transition-timing-function: linear;
            }
        }
    }
}