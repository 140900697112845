@import "../../mixins.scss";

.LineRatePage {
    .lHeight {
        height: 48px;
    }

    .GetDemo {
        margin-top: 20px;
        padding: 40px;

        @include for-768 {
            padding: 24px;
        }
    }

    .brandColor {
        color: #2563EB;
    }

    .tableWrapper {
        position: relative;

        .map {
            max-height: 650px;
            width: auto;
            position: absolute;
            top: 326px;
            left: 495px;
            z-index: -1;

            @include for-768 {
                top: 400px;
                left: 270px;
            }

            @include for-395 {
                display: none;
            }
        }
    }

    .blockSection1 {
        margin-top: 200px;

        @include for-768 {
            margin-top: 160px;
        }
        @include for-395 {
            margin-top: 80px;
        }

        .title {
            margin-bottom: 60px;

            @include for-395 {
                margin-bottom: 40px;
                font-size: 36px;
                line-height: 40px;
            }
        }

        .blockContainer1 {
            display: grid;
            grid-template-columns: repeat(2, 495px);
            column-gap: 123px;

            @include for-768 {
                max-width: 550px;
                grid-template-columns: 1fr;
                gap: 28px;
            }
        }

        .blockTitle {
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;

            @include for-395 {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .blockDescription {
            display: flex;
            flex-direction: column;
            gap: 40px;

            @include for-768 {
                gap: 28px;
            }

            .text {
                margin: 0;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
            }

            .btn {
                width: fit-content;
            }
        }

        .cardList1 {
            margin: 80px 0 0;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            font-size: 16px;
            line-height: 24px;
            color: #334155;
            list-style-type: none;

            @include for-768 {
                grid-template-columns: 1fr;
            }

            @include for-395 {
                margin: 60px 0 0;
            }

            .cardListItem1 {
                display: flex;
                flex-direction: column;
                gap: 40px;
                background-color: #F8FAFC;
                border-radius: 16px;
                padding: 40px;

                @include for-768 {
                    padding: 24px;
                    width: auto;
                }

                @include for-395 {
                    padding: 14px;
                }
            }

            .cardListImg1 {
                height: 44px;
                width: 44px;
                min-width: 44px;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                border-radius: 50%;
                background-color: #ffffff;
                color: #94A3B8;
            }

            .cardListTitle1 {
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
                color: #0F172A;
            }
        }
    }

    .content {
        margin: 60px auto 200px;
        width: 1216px;

        @include for-768 {
            width: 684px;
            margin-bottom: 160px;
        }

        @include for-395 {
            width: 324px;
        }

        h1 {
            margin-top: 64px;
            margin-bottom: 40px;
            font-size: 48px;
            min-height: 48px;
            font-weight: 600;
            color: #0F172A;

            @include for-768 {
                margin-bottom: 64px;
            }

            @include for-395 {
                margin-top: 40px;
                margin-bottom: 40px;
                font-size: 36px;
                line-height: 40px;
            }
        }

        .locations {
            display: grid;
            grid-template-columns: auto 253px;
            gap: 10px;
            align-items: center;

            @include for-768 {
                display: flex;
                flex-direction: column;
                gap: 28px;
                align-items: flex-start;
            }

            @include for-395 {
                gap: 20px;
            }
        }

        .buttonGroup {

            @include for-395 {
                display: none;
            }

            button {
                border-width: 1.5px;
                border-style: solid;
                border-color: #E2E8F0;
                font-size: 14px;
                line-height: 20px;
                color: #94A3B8;
                font-weight: 400;
                padding: 10.5px 20px !important;
                margin: 0px !important;
                box-sizing: border-box;

                &:hover {
                    border-color: #E2E8F0;
                }

                &.active {
                    border: 1.5px solid #2563EB;
                    background-color: #EFF6FF;
                    font-size: 14px;
                    line-height: 20px;
                    color: #334155;
                    font-weight: 600;
                }
            }
        }

        .locationsMobile {
            display: none;

            @include for-395 {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
            }
        }

        .sort {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
        }

        .select {
            .title {
                font-size: 14px;
                line-height: 20px;
                color: #94A3B8;
                font-weight: 600;
            }

            fieldset {
                border: 0px;
            }

            .MuiSelect-select {
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                color: #2563EB;
                padding: 12px 10px 12px 20px;
                padding-right: 26px;
            }

            .selectIcon {
                color: #2563EB;
                position: absolute;
                right: 0px;
                pointer-events: none;

                path {
                    stroke: #2563EB;
                }

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .LineRateTable {
            margin: 40px 0px 0px;

            @include for-768 {
                margin-top: 64px;
            }

            @include for-395 {
                margin: 40px 0px 0px;
            }

            .rateTableLine {
                display: grid;
                grid-template-columns: 80px 200px 168px auto;
                margin-bottom: 12px;
                height: 60px;
                overflow: hidden;
                color: #334155;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                align-items: center;
                padding: 0px 10px;


                @include for-768 {
                    grid-template-columns: 40px 180px 128px auto;
                }

                @include for-395 {
                    grid-template-columns: 34px 120px auto;
                    margin-bottom: 0px;
                    gap: 2px;
                }

                &:hover {
                    background-color: #F8FAFC;
                    border-radius: 4px;
                }

                &.header {
                    border-bottom: 1.5px solid #E2E8F0;
                    height: 56px;
                    align-items: flex-start;
                    padding: 0px;
                    margin: 0px 10px 20px;

                    &:hover {
                        background-color: transparent;
                    }

                    @include for-395 {
                        height: 90px;
                    }
                }

                .title {
                    margin-top: 12px;
                    color: #304564;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;

                    &.dataValueTitle {
                        @include for-395 {
                            display: none;
                        }
                    }

                    .notMobile {
                        display: block;

                        @include for-395 {
                            display: none;
                        }
                    }

                    .mobile {
                        display: none;

                        @include for-395 {
                            display: block;
                        }
                    }
                }

                .company {
                    display: flex;
                    height: 40px;
                    overflow: hidden;

                    .logo {
                        display: flex;
                        align-items: center;

                        img {
                            max-width: 126px;
                            max-height: 40px;
                        }
                    }
                }

                .percent {
                    display: grid;
                    grid-template-columns: auto 44px;

                    @include for-395 {
                        grid-template-columns: 48px 40px 68px;
                        gap: 0px;
                    }

                    .arrow {
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;

                        &.green {
                            color: #16A34A;
                        }

                        &.red {
                            color: #DC2626;
                        }

                        img {
                            margin-right: 4px;
                        }

                        @include for-395 {
                            &.hide {
                                display: block !important;
                            }
                        }
                    }

                    .dataValueMobile {
                        display: none;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;
                        color: #2563EB;

                        @include for-395 {
                            display: block;
                            text-align: right;
                        }
                    }
                }

                .dataValue {
                    height: 40px;
                    overflow: hidden;
                    display: grid;
                    grid-template-columns: 70px auto;
                    margin-left: 10px;
                    gap: 24px;

                    @include for-395 {
                        display: none;
                    }

                    .value {
                        display: flex;
                        align-items: center;
                        color: #2563EB;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;
                        flex: 1;
                        justify-content: flex-end;
                    }

                    .line {

                    }
                }
            }

            .dataBarMobile {
                display: none;

                @include for-395 {
                    display: block;
                    background: rgba(241, 245, 249, 1);
                }
            }

            .bg {
                height: 40px;
                background-color: #2563EB;
                width: 10px;
                border-radius: 4px;

                @include for-395 {
                    height: 10px;
                    border-radius: 2px;
                }
            }
        }

        .description {
            background-color: #F8FAFC;
            border-radius: 4px;
            font-size: 14px;
            line-height: 20px;
            color: #334155;
            font-weight: 400;
            padding: 12px;
            display: flex;
            width: fit-content;
            flex-direction: row;
            gap: 12px;
            margin-top: 64px;

            @include for-395 {
                display: grid;
                grid-template-columns: 20px auto;
            }

            a {
                color: #2563EB;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .download {
            color: #2563EB;
            margin-top: 24px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            width: fit-content;
        }
    }
}

.LineRatePage-sortItems, .LineRatePage-download {
    .MuiPaper-root {
        box-shadow: 0px 4px 6px -2px #94A3B80D, 0px 10px 15px -3px #94A3B81A;
        border-radius: 4px;    }

    ul {
        margin: 0px;
        padding: 0px;

        li {
            padding: 12px 20px;
            font-size: 14px;
            line-height: 20px;
            color: #334155;
            font-weight: 400;
            background-color: #FFF !important;

            &.Mui-selected {
                font-weight: 600;
            }

            &:hover {
                background-color: #F1F5F9 !important;
            }
        }
    }
}

.LineRatePage-download {
    .MuiPaper-root {
        margin-left: 50px;
    }

    li {
        min-width: 200px;

        &.Mui-selected {
            font-weight: 600;
            background-color: #F1F5F9;
        }

        &:hover {
            background-color: #F1F5F9 !important;
            text-decoration: none;
        }
    }

    a {
        padding: 0px;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
        font-weight: 400;
        background-color: transparent !important;

        &:hover {
            text-decoration: none;
        }
    }
}
